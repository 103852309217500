// Core
import React from 'react';
import PropTypes from 'prop-types';
// Styles
import './Overview.scss';
// Components
import OverviewCard from '../layout/OverviewCard';
import ScoreChart from '../chart/ScoreChart';

import {skillResultPropType} from '../../propTypes';

/**
 * Displays an array of *ScoreChart* components to give users an overview of their performance
 * for each skill.
 *
 * The *onSelectSkill* handler can be used to trigger the display more detailed information
 * when users click on a chart.
 *
 */
const Overview = (props) => {
    return (
        <div className="overview">
            <ol>
                {props.skillResults.map((skillResult, i) => {
                    const skill = skillResult.name;

                    let feedback;

                    if (props.showSkillFeedback
                        && !skillResult.subskills.length) {
                        feedback = {
                            feedback: skillResult.feedbackText,
                            answersLinkTo: {
                                pathname: `${props.path}/${skill}/1`
                            }
                        };
                    }

                    return (
                        <OverviewCard
                            active={skill === props.selectedSkill}
                            feedback={feedback}
                            key={i}
                            heading={skillResult.displayName}
                            onClick={props.onSelectSkill}
                            skill={skill}
                        >
                            <ScoreChart
                                color={props.theme.scoreDisplay.color}
                                cutoff={skillResult.cutoffs && skillResult.cutoffs[0]}
                                fuzziness={props.fuzziness}
                                isFuzzy={props.hasFuzzyScoreCharts}
                                percentageResult={skillResult.percentageResult}
                            />
                        </OverviewCard>
                    );
                })}
            </ol>
        </div>);
};

Overview.defaultProps = {
    fuzziness: 15,
    hasFuzzyScoreCharts: true,
    showSkillFeedback: true
};

Overview.propTypes = {
    /** an array of skill results (typically from API response) */
    skillResults: PropTypes.arrayOf(skillResultPropType).isRequired,
    /** size of the ending fade to transparent in *ScoreCharts*, if hasFuzzyScoreCharts prop === true */
    fuzziness: PropTypes.number,
    /** whether *ScoreChart* bars should fade to transparent */
    hasFuzzyScoreCharts: PropTypes.bool,
    /** onSelectSkill handler */
    onSelectSkill: PropTypes.func,
    /** path of containing page, set this prop if showSkillFeedback === true to ensure answer links work correctly */
    path: PropTypes.string,
    /** the currently selected skill */
    selectedSkill: PropTypes.string,
    /** whether to show skill feedback on *ScoreCharts* if there are no subskills */
    showSkillFeedback: PropTypes.bool,
    /** theme data */
    theme: PropTypes.object.isRequired
};

export default Overview;
