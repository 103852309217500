import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { axisThemePropType } from '@nivo/core';
import {cutoffsPropType} from '../../propTypes';

class ScoreChartAxisTick extends PureComponent {
    render() {
        const {
            cutoff,
            value: _value,
            x: _x,
            y: _y,
            opacity,
            rotate,
            format,
            lineY,
            textX: _textX,
            textY: _textY,
            textAnchor: _textAnchor,
            theme
        } = this.props;

        let value = _value;

        if (format !== undefined) {
            value = format(value);
        }

        let x = _x;
        const y = _y - 11;
        let textX = _textX;
        const textY = _textY + 12;
        let textAnchor = _textAnchor;
        const dotRadius = lineY / 2;
        let fontWeight = 'normal';

        if (value === 0) {
            x = x + dotRadius;
            textX = -dotRadius;
            textAnchor = 'start';
        }

        if (value === 100) {
            x = x - dotRadius;
            textX = dotRadius;
            textAnchor = 'end';
        }

        if (cutoff) {
            if (value === cutoff.value) {
                value = cutoff.name;
                fontWeight = 'bold';
            }

            if (value === 0) {
                value = `Below ${cutoff.name}`;
            }

            if (value === 100) {
                value = `Above ${cutoff.name}`;
            }
        }

        let gStyle = { opacity };

        const dotcolor = '#BFBEBE';

        return (
            <g
                transform={`translate(${x},${y})`}
                style={gStyle}
            >
                <circle cx="0" cy="0" r={dotRadius} fill={dotcolor} />
                <text
                    fontWeight={fontWeight}
                    textAnchor={textAnchor}
                    dx={textX}
                    dy={textY}
                    transform={`rotate(${rotate})`}
                    style={theme.axis.ticks.text}
                >
                    {value}
                </text>
            </g>
        );
    }
}

ScoreChartAxisTick.propTypes = {
    cutoff: cutoffsPropType,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)])
        .isRequired,
    format: PropTypes.func,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    lineX: PropTypes.number.isRequired,
    lineY: PropTypes.number.isRequired,
    textX: PropTypes.number.isRequired,
    textY: PropTypes.number.isRequired,
    textAnchor: PropTypes.string.isRequired,
    opacity: PropTypes.number.isRequired,
    rotate: PropTypes.number.isRequired,
    theme: PropTypes.shape({
        axis: axisThemePropType.isRequired,
    }).isRequired,
    tickIndex: PropTypes.number
};

ScoreChartAxisTick.defaultProps = {
    opacity: 1,
    rotate: 0,
};

export default ScoreChartAxisTick;
