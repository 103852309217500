// Core
import React from 'react';
// Styles
import './Nav.scss';
// Components
import Icon from '../info/Icon';
import PropTypes from 'prop-types';
/** Renders a *nav* element, with an optional icon, menu button and heading */
const Nav = ({
    children,
    hidden,
    icon,
    label,
    menu,
    onClick,
    popup
}) => (
    <nav className="diagnostic-nav">
        <h2>{label || 'Main Navigation'}</h2>
        <button aria-haspopup={popup} onClick={onClick}>
            {icon && <Icon icon={icon} />}
            {menu}
        </button>
        <ol hidden={hidden}>
            {children}
        </ol>
    </nav>
);

Nav.propTypes = {
    /** child elements */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    /** show or hide children */
    hidden: PropTypes.bool,
    /** material-ui icon name */
    icon: PropTypes.string,
    /** heading label */
    label: PropTypes.string,
    /** menu label */
    menu: PropTypes.string,
    /** onClick handler */
    onClick: PropTypes.func,
    /** whether button has *aria-haspopup* attribute */
    popup: PropTypes.bool
};

export default Nav;
