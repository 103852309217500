import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    CallToAction,
    Layout,
    Overview,
    resultPropType,
    ScoreChartLegend,
    themePropType
} from '../diagnostic-components';

import LearnerSkill from '../templates/LearnerSkill';
import LoadingLayout from '../components/info/LoadingLayout';

const Result = (props) => {
    const [legendHidden, setLegendHidden] = useState(true);
    const [selectedSkill, setSelectedSkill] = useState();

    if (!props.result) {
        return <LoadingLayout title="Awaiting results..." to="/sessions" />;
    }

    const handleLegendClick = () => setLegendHidden(!legendHidden);

    const skillResults = props.result.overallResults.skills;

    const title = `${props.result.examTitle} | ${new Date(props.result.created).toLocaleDateString()}`;

    return (
        <div className="learner-result">
            <Layout
                icon="arrow_back"
                title={title}
                to="/sessions"
            >
                <CallToAction
                    skillDisplayNames={skillResults.map((skill) => skill.displayName)}
                />
                <Overview
                    skillResults={skillResults}
                    onSelectSkill={setSelectedSkill}
                    path={props.path}
                    selectedSkill={selectedSkill}
                    theme={props.theme}
                />
                {selectedSkill && (
                    <LearnerSkill
                        skillResult={skillResults.find(r => r.name === selectedSkill)}
                        level="B2"
                        // pass key prop to re-instantiate component when skill changes
                        key={selectedSkill}
                        path={props.path}
                        theme={props.theme}
                    />
                )}
                <ScoreChartLegend
                    color={props.theme.scoreDisplay.color}
                    hidden={legendHidden}
                    onClick={handleLegendClick}
                />
            </Layout>
        </div>);
};

Result.propTypes = {
    path: PropTypes.string.isRequired,
    result: resultPropType,
    theme: themePropType.isRequired
};

export default Result;
