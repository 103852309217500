import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import API from '../lib/api';

import ExamSessions from './ExamSessions';

import {
    navItemsPropType
} from '../../src/diagnostic-components';

import {TITLE_PREFIX} from '../Constants';

class ExamSessionsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null
        };
    }

    async componentDidMount() {
        document.title = TITLE_PREFIX + 'Sessions';

        let results;

        try {
            const sessions = await API.get(`/results/student/${this.props.studentId}`);

            results = await Promise.all(sessions.map(async (session) => {
                const result = await API.get(`/results/results/${session}`, {nullOnMissing: true});

                if(!result) {
                    console.error(`Results for ${session} not found.`);
                }

                return result;
            }));
        } catch (error) {
            return this.setState({error});
        }

        this.setState({
            results: results.filter(result => result !== null)
        });
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }

        return (
            <ExamSessions
                navItems={this.props.navItems}
                results={this.state.results}
                url={this.props.match.url}
            />
        );
    }
}

ExamSessionsContainer.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
    navItems: navItemsPropType,
    studentId: PropTypes.string
};

const mapStateToProps = state => ({
    studentId: state.auth.user.id
});

export default connect(mapStateToProps)(ExamSessionsContainer);
