import PropTypes from 'prop-types';

const colorPropType = PropTypes.shape({
    low: PropTypes.string,
    high: PropTypes.string
});

const tickPositionsPropType = PropTypes.arrayOf(PropTypes.number);

const scoreDisplayPropType = PropTypes.shape({
    showScores: PropTypes.bool,
    color: colorPropType,
    tickPositions: tickPositionsPropType
});

const themePropType = PropTypes.shape({
    id: PropTypes.string,
    feedbackOptions: PropTypes.shape({
        showAnswers: PropTypes.bool,
        showSkillFeedback: PropTypes.bool,
        showInstruction: PropTypes.bool,
        showOverallResults: PropTypes.bool,
        showSubskillFeedback: PropTypes.bool
    }),
    name: PropTypes.string,
    scoreDisplay: scoreDisplayPropType
});

export {
    colorPropType,
    tickPositionsPropType,
    scoreDisplayPropType,
    themePropType
};
