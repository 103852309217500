// Core
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
// Styles
import './Error.scss';

export const genericNotFoundError = {
    title: 'Page not found',
    status: 404,
    message: `We can't find that page! If something should be here please ask your admin to inform us here at Cambridge Assessment English.`
};

// expects status and text
function Error({error}) {
    console.error(error);

    // axios errors will have a status code in their 'response' object
    const status = error.status || (error.response && error.response.status);

    const header = `${error.title ? error.title : 'An error has occurred'}${status ? ` (${status})` : ''}`;

    return (
        <section className="errorInformation">
            <div className="errorText">
                <div>
                    <h1>{header}</h1>
                    <p>{error.message}</p>
                </div>
            </div>
            <div className={`errorIllustration status${status}`}/>
        </section>);
}

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = { error: null };

        const { history } = props;

        history.listen(() => {
            if (this.state.error) {
                this.setState({
                    error: null,
                });
            }
        });
    }

    componentDidCatch(error, info) {
        console.log(error, info);

        this.setState({ error });
    }

    render() {
        if (this.state.error) {
            return <Error error={this.state.error} />;
        }

        return this.props.children;
    }
}

const ErrorBoundaryWithRouter = withRouter(ErrorBoundary);

export default Error;

export {
    ErrorBoundaryWithRouter as ErrorBoundary
};