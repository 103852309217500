import React, {Component} from 'react';
import API from '../lib/api';
import Paper from '@material-ui/core/Paper';

class Cohort extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ids: [],
            summary: {}
        };
    }

    componentDidMount() {
        const ids = new URL(window.location.href).searchParams.get("ids").split(',');
        API.post('/cohort', {body: ids}).then(summary => this.setState({ids, summary}))
    }

    render() {
        return <Paper>
            <h1>Cohort Summary</h1>
            <pre>
                {JSON.stringify(this.state.summary, null, 2)}
            </pre>
        </Paper>
    }
}


export default Cohort;