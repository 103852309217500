import React, {Component} from 'react';
import Measure from 'react-measure';
import PropTypes from 'prop-types';

/**
 * Wraps SVG content, passing height and width props to the child element
 * on resize.
 *
 * The wrapped component will take up 100% of the available height and width.
 *
 * The component requires an initial height in pixels or nothing will display: target
 * the *responsive-plot-wrapper* class in your CSS to set this up.
 */
class ResponsivePlotWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dimensions: {
                width: -1,
                height: -1
            }
        };

        this.handleResize = this.handleResize.bind(this);
    }

    handleResize(contentRect) {
        this.setState({dimensions: contentRect.bounds});
    }

    render() {
        const {
            props: { children },
            handleResize,
            state: { dimensions: { width, height }}
        } = this;

        const shouldRender = width > 0 && height > 0;

        const childrenWithDimensions = React.Children.map(children, child =>
            React.cloneElement(child, {width, height})
        );

        return (
            <Measure
                bounds
                onResize={handleResize}
            >
                {({measureRef}) => (
                    <div className="responsive-plot-wrapper" ref={measureRef}>
                        {shouldRender && childrenWithDimensions}
                    </div>
                )}
            </Measure>
        );
    }
}

ResponsivePlotWrapper.propTypes = {
    /** child element(s) - child props should include *width* and *height* */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default ResponsivePlotWrapper;
