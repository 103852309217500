import PropTypes from 'prop-types';

const cutoffsPropType = PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number
});

const subskillResultPropType = PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    displayName: PropTypes.string,
    feedbackText: PropTypes.string,
    levelAchieved: PropTypes.string,
    aboveCutoff: PropTypes.bool,
    totalCorrect: PropTypes.number,
    maxPossibleTotalCorrect: PropTypes.number,
    percentageResult: PropTypes.number,
    cutoffs: PropTypes.arrayOf(cutoffsPropType)
});

const skillResultPropType = PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    displayName: PropTypes.string,
    feedbackText: PropTypes.string,
    levelAchieved: PropTypes.string,
    aboveCutoff: PropTypes.bool,
    totalCorrect: PropTypes.number,
    maxPossibleTotalCorrect: PropTypes.number,
    percentageResult: PropTypes.number,
    cutoffs: PropTypes.arrayOf(cutoffsPropType),
    subskills: PropTypes.arrayOf(subskillResultPropType)
});

const overallResultsPropType = PropTypes.shape({
    levelAchieved: PropTypes.string,
    totalCorrect: PropTypes.number,
    maxPossibleTotalCorrect: PropTypes.number,
    percentageResult: PropTypes.number,
    aboveCutoff: PropTypes.bool,
    cutoffs: PropTypes.arrayOf(cutoffsPropType),
    skills: PropTypes.arrayOf(skillResultPropType)
});

const responsesPropType = PropTypes.shape({
    givenAnswer: PropTypes.string,
    correctAnswer: PropTypes.string,
    correct: PropTypes.bool,
    attempted: PropTypes.bool,
    context: PropTypes.string,
    explanation: PropTypes.string,
    inputText: PropTypes.string,
    taskReference: PropTypes.string,
    questionReference: PropTypes.string,
    questionNumber: PropTypes.number,
    skill: PropTypes.string,
    subskill: PropTypes.string
});

const resultPropType = PropTypes.shape({
    examId: PropTypes.string,
    sessionId: PropTypes.string,
    studentId: PropTypes.string,
    created: PropTypes.string,
    overallResults: overallResultsPropType,
    templateId: PropTypes.string,
    examTitle: PropTypes.string,
    responses: PropTypes.arrayOf(responsesPropType)
});

export {
    cutoffsPropType,
    subskillResultPropType,
    skillResultPropType,
    overallResultsPropType,
    responsesPropType,
    resultPropType
};
