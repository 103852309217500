import React, {Component} from "react";

class VerticalBarChart extends Component {

    barValues = [];

    constructor(props) {
        super(props);

        this.barValues = this.props.results.cutoffSummaries.map(item => item.count);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
            let anyChanges = false;
            if(prevProps.results !== this.props.results) {
                this.barValues = this.props.results.cutoffSummaries.map(item => item.count);
                anyChanges = true;
            }
            if(prevProps.maxScale !== this.props.maxScale) {
                anyChanges = true;
            }
            if(anyChanges) {
                this.reDrawBarChart();
            }
    }

    componentWillUnmount() {
        window.cohortSkillBarchart.destroy();
    }

    reDrawBarChart = () => {

        if(window.cohortSkillBarchart) {
            window.cohortSkillBarchart.destroy();
        }

        const ctx = document.getElementById('cohortSkillBarChart').getContext('2d');


        const data = {
            labels: ['BELOW', 'ON TARGET', 'ABOVE'],
            datasets: [{
                data: this.barValues,
                backgroundColor: [
                    'rgba(7, 153, 216, 1)',
                    'rgba(190, 47, 173, 1)',
                    'rgba(47, 7, 203, 1)'
                ],
                hoverBackgroundColor: [
                    'rgba(7, 153, 216, 1)',
                    'rgba(190, 47, 173, 1)',
                    'rgba(47, 7, 203, 1)'
                ],
            }]
        };

        const options = {
            responsive: true,
            maintainAspectRatio: true,
            legend: {
                display: false,
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: this.props.maxScale + (this.props.maxScale * 0.2),
                        display: false
                    },
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                        display: false
                    },
                }],
                xAxes: [{
                    ticks: {
                        fontColor: "rgba(90, 90, 90, 1)",
                        padding: 0,
                        stepSize: 1,
                        beginAtZero: true,
                    },
                    gridLines: {
                        offsetGridLines: false,
                        color: "rgba(112, 112, 112, 1)",
                        lineWidth: 2,
                        display: false
                    },
                    categoryPercentage: 1.0,
                    barPercentage: 1,
                    barThickness : 60
                }],

            },
            animation: {
                onComplete: function () {
                    const ctx = this.chart.ctx;
                    //set globals for font
                    ctx.font = window.Chart.helpers.fontString(window.Chart.defaults.global.defaultFontFamily, 'normal', window.Chart.defaults.global.defaultFontFamily);
                    ctx.fillStyle = "#707070";
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    data.datasets.forEach(dataset =>
                    {
                        for (let i = 0; i < dataset.data.length; i++) {
                            for(const key in dataset._meta)
                            {
                                const model = dataset._meta[key].data[i]._model;
                                ctx.fillText(dataset.data[i], model.x, model.y - 5);
                            }
                        }
                    });
                }
            },
            events: []
        };

        window.cohortSkillBarchart = new window.Chart(ctx, {
            type: 'bar',
            data: data,
            options: options,
        });
    };

    componentDidMount() {
        this.reDrawBarChart();
    }

    render() {
        return (
            <canvas width="100" height="50" id='cohortSkillBarChart'/>
        )
    }
}

export default VerticalBarChart;