import React, {Component} from "react";

import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import TastySpie from '../components/ieltsi/TastySpie';
import {IeltsiFooter, IeltsiHeader} from '../components/ieltsi/IeltsiHeaderAndFooter';
import {Container, Dialog, DialogContent} from "@material-ui/core";
import {SkillHeaderMenu} from '../components/ieltsi/SkillHeaderMenu';
import FeedbackPoll from "../components/FeedbackPoll";
import {FireGoogleAnalyticsEvent} from '../lib/googleAnalytics';


function IetlsiPieOnlyWhenReady(props) {
    document.querySelector('body').className = 'IeltsiResults';

    let component;

    // Some null check and waiting for data bits
    if( ! props.result ) {
        component = <h3>Loading...</h3>
    } else if( props.result.overallResults.skills.length < 1 ||  props.result.overallResults.skills[0].subskills.length < 2) {
        component = <h3>Invalid data - one skill and at least two subskills required</h3>
    } else {
        component = <IetlsiPie subskills={props.result.overallResults.skills[0].subskills} {...props} />
    }

    return <>
        <IeltsiHeader />
        {component}
        <IeltsiFooter />
    </>
}

class IetlsiPie extends Component {

    constructor(props) {
        super(props);

        // Smallest skill is loaded first as that's assumed to be most useful for feedback
        const biggestSubskill = this.props.subskills.reduce( (acc, subskill) => (acc.percentageResult < subskill.percentageResult) ? acc : subskill );

        this.state = {
            subskills: this.props.subskills,
            selectedSubskill: biggestSubskill,
            open: false,
            modalData: null
        };

        this.session = this.props.match.params.session;

    }

    componentDidMount() {
        window.dataLayer.push({
            'event': 'pageview',
            'pageUrl': window.location.pathname,
            'pageTitle': 'IELTSI PIE'
        });
    }

    handleClick = () => {
        this.setState({
            open: !this.state.open
        })
    };

    handleHeaderClick = skill => {

        FireGoogleAnalyticsEvent('send', {
            hitType: 'event',
            eventCategory: 'Subskill Info Header',
            eventAction: `Click - ${this.session}`,
            eventLabel: skill.name,
        });

        this.setState({
            open: !this.state.open,
            modalData: skill
        })
    };

    handleButton = skillName => {

        FireGoogleAnalyticsEvent('send', {
            hitType: 'event',
            eventCategory: 'Check Answers Button',
            eventAction: `Click - ${this.session}`,
            eventLabel: skillName
        });

        this.props.history.push({
            pathname: `/results/${this.props.match.params.session}/${skillName}`,
        });
    };

    handleSelectionChange = selectedSubskill => {

        FireGoogleAnalyticsEvent('send', {
            hitType: 'event',
            eventCategory: 'SubskillHeaders',
            eventAction: `Click - ${this.session}`,
            eventLabel: selectedSubskill.name
        });

        this.setState({selectedSubskill});
    };

    render() {
        if(this.state.subskills) {
            const subskill = this.state.selectedSubskill;
            const modalData = this.state.modalData ? this.state.modalData.description : null;
            return (
                <>
                    <Container maxWidth={'sm'}>
                        <section style={{display: 'flex'}}>
                            <div style={{width: '70%', alignSelf: 'center', margin: '36px 0'}}>
                                <TastySpie subskill={subskill}
                                           subskills={this.state.subskills}
                                           onSegmentClick={subskill =>  this.setState({open: true, modalData: subskill})}
                                           onSelectionChange={selectedSubskill => this.setState({selectedSubskill})}
                                />
                            </div>
                        </section>

                        <SkillHeaderMenu subskills={this.state.subskills}
                                         selectedSubskill={this.state.selectedSubskill}
                                         onSelectionChange={selectedSubskill => this.handleSelectionChange(selectedSubskill)} />

                        <section style={{paddingBottom: '120px'}}>
                            <h1 className={'title-info'} onClick={() => this.handleHeaderClick(subskill)} key={subskill.name}><span>i</span>{subskill.displayName}</h1>
                            <div dangerouslySetInnerHTML={{__html: subskill.feedbackText}}/>
                            <button className={'check-answers'} onClick={() => this.handleButton(subskill.name)}>Check Answers</button>
                        </section>
                    </Container>

                    <FeedbackPoll/>

                    <Dialog open={this.state.open} onClose={this.handleClick}>
                        <DialogContent dangerouslySetInnerHTML={{__html: modalData}}/>
                    </Dialog>
                </>

            )

        }
        return 'loading...';
    }
}


IetlsiPie.propTypes = {
    result: PropTypes.object
};

export default withRouter(IetlsiPieOnlyWhenReady);