import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MotionWrapper from '../wrapper/MotionWrapper';
import FuzzyGradient from '../color/FuzzyGradient';
import Utils from '../../lib/Utils';

/** Default bar for CutoffChart */
const CutoffBar = (props) => {
    const renderBar = (height) => {
        const {
            x,
            y,
            color,
            fuzziness,
            isFuzzy,
            width
        } = props;

        const barHeight = height >= 0 ? height : 0;
        const gradientId = `cutoff-bar-gradient-${Utils.getUniqueId()}`;

        return (
            <Fragment>
                {isFuzzy &&
                <defs>
                    <FuzzyGradient
                        isVertical
                        size={barHeight}
                        color={color}
                        fuzziness={fuzziness}
                        id={gradientId}
                    />
                </defs>}
                <rect
                    x={x}
                    y={y}
                    fill={isFuzzy ? `url(#${gradientId})` : color}
                    width={width >= 0 ? width : 0}
                    height={barHeight}
                />
            </Fragment>
        );
    };

    const {
        animate,
        fuzziness,
        isFuzzy,
        height,
        maxHeight
    } = props;

    const fuzzyBarHeight = height + fuzziness > maxHeight ? maxHeight : height + fuzziness;

    return (
        <MotionWrapper
            animate={animate}
            render={renderBar}
            value={isFuzzy ? fuzzyBarHeight : height}
        />
    );
};

CutoffBar.defaultProps = {
    animate: false,
    fuzziness: 15
};

CutoffBar.propTypes = {
    /** whether to animate the bar */
    animate: PropTypes.bool,
    /** bar colour */
    color: PropTypes.string,
    /** size of the ending fade to transparent, in pixels */
    fuzziness: PropTypes.number,
    /** bar height */
    height: PropTypes.number,
    /**
     * whether to add a fade to transparent to the right side of the bar;
     * ignored if a custom *Bar* component is provided
     */
    isFuzzy: PropTypes.bool,
    /** maximum possible height the bar could occupy */
    maxHeight: PropTypes.number.isRequired,
    /** bar width */
    width: PropTypes.number,
    /** bar x position */
    x: PropTypes.number,
    /** bar y position */
    y: PropTypes.number
};

export default CutoffBar;
