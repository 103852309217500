// Core
import React, {useState} from 'react';
import PropTypes from 'prop-types';

// Styles
import './Review.scss';
// Components
import {
    Card,
    Layout,
    responsesPropType,
    ReviewFooter,
    ReviewNav,
    ReviewQuestion
} from '../diagnostic-components';

import LoadingLayout from '../components/info/LoadingLayout';

const Review = (props) => {
    const [answerHidden, setAnswerHidden] = useState(false);

    if (!props.responses) {
        return <LoadingLayout title="Awaiting review..." to={props.resultsHomeUrl} />;
    }

    const handleClick = () => setAnswerHidden(!answerHidden);

    // Get the zero-indexed question number
    const responseIndex = props.question - 1;
    const reviewResponse = props.responses[responseIndex];

    const heading = (reviewResponse.questionNumber === 0)
        ? `Page ${props.question}`
        : `Question ${reviewResponse.questionNumber}`;

    return (
        <Layout
            icon="arrow_back"
            title={props.skill}
            to={props.resultsHomeUrl}
            variant='page review'>

            <ReviewNav
                questionCount={props.responses.length}
                questionIndex={responseIndex}
            />

            <Card
                heading={`<h2 class="question-header">${heading}</h2>`}
                variant="card inner clear"
            >
                <ReviewQuestion
                    answerHidden={answerHidden}
                    question={reviewResponse}
                />

                {reviewResponse.inputText && (
                    <ReviewFooter
                        onClick={handleClick}
                        showStimulusLink={!answerHidden}
                    />
                )}
            </Card>
        </Layout>
    );
};

Review.propTypes = {
    responses: PropTypes.arrayOf(responsesPropType),
    resultsHomeUrl: PropTypes.string.isRequired
};

export default Review;
