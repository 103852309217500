import React from 'react';
import PropTypes from 'prop-types';

/**
 * Creates a linear gradient, the end portion of which fades to transparent.
 */
const FuzzyGradient = ({
    color,
    fuzziness,
    id,
    isVertical,
    size
}) => {
    const stopPoint = size > 0 ? Math.round((size - (fuzziness * 2)) / size * 100) : 0;

    return (
        <linearGradient id={id} {...(isVertical ? { x1: '0', x2: '0', y1: '0', y2: '1' } : {})}>
            <stop offset="0%" stopColor={color} />
            <stop offset={`${stopPoint}%`} stopColor={color} />
            <stop offset="100%" stopColor={color} stopOpacity="0.0" />
        </linearGradient>
    );
};

FuzzyGradient.defaultProps = {
    isVertical: false
};

FuzzyGradient.propTypes = {
    /** main colour */
    color: PropTypes.string.isRequired,
    /** size of the ending fade to transparent, in pixels */
    fuzziness: PropTypes.number.isRequired,
    /** gradient id (must be unique within the page) */
    id: PropTypes.string.isRequired,
    /** whether the gradient is vertical */
    isVertical: PropTypes.bool,
    /** the size (width or height) of the element/component that will be filled with the generated gradient */
    size: PropTypes.number.isRequired
};

export default FuzzyGradient;
