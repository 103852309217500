import React from 'react';
import CutoffPlot from './CutoffPlot';
import ResponsivePlotWrapper from '../wrapper/ResponsivePlotWrapper';
import PropTypes from 'prop-types';
import './CutoffChart.scss';
import {colorPropType, cutoffsPropType} from '../../propTypes';

/**
 * An animated bar chart which displays the percentage of cutoff achieved.
 *
 * The chart is responsive, but you'll need to make sure its child *ResponsivePlotWrapper*
 * component has an initial height in pixels or nothing will display: target the
 * *responsive-plot-wrapper* class in your CSS to set this up.
 */
const CutoffChart = ({
    barLabel,
    color,
    cutoff,
    fuzziness,
    heading,
    isFuzzy,
    percentageResult
}) => (
    <div className="cutoff-chart">
        {heading && <h3>{heading}</h3>}
        <ResponsivePlotWrapper>
            <CutoffPlot
                animate
                cutoff={cutoff}
                fuzziness={fuzziness}
                isFuzzy={isFuzzy}
                percentageResult={percentageResult}
                barLabel={barLabel}
                color={color}
            />
        </ResponsivePlotWrapper>
    </div>
);

CutoffChart.defaultProps = {
    fuzziness: 15,
    isFuzzy: true
};

CutoffChart.propTypes = {
    /** label displayed below the chart bar */
    barLabel: PropTypes.string.isRequired,
    /** defines colour range for chart bar */
    color: colorPropType.isRequired,
    /** cutoff used to display a bar and label indicating the cutoff point  */
    cutoff: cutoffsPropType,
    /** size of the ending fade to transparent, in pixels */
    fuzziness: PropTypes.number,
    /** chart heading */
    heading: PropTypes.string,
    /** whether *CutoffChart* bars should fade to transparent */
    isFuzzy: PropTypes.bool,
    /** percentage result to display */
    percentageResult: PropTypes.number.isRequired
};

export default CutoffChart;
