// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Styles
import './Result.scss';
// Components
import Result from './Result';

import defaultTheme from '../temp-defaults/default-theme';
import {TITLE_PREFIX} from '../Constants';
import IetlsiPie from "../templates/IetlsiPie";
import GetResultsWithRetries from '../lib/resultsWithRetry';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';

class ResultContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            itemNum: 0,
            awaitingFullResults: false
        };
    }

    async componentDidMount() {
        document.title = TITLE_PREFIX + 'Results Summary';

        const session = this.props.match.params.session;

        GetResultsWithRetries(
            session,
            result => this.setState({result, awaitingFullResults: false}),
            partialResult => this.setState({awaitingFullResults: true, sessionStarted: partialResult.created}),
            error => this.setState({error})
        );
    }

    render() {

        if (this.state.error) {
            throw this.state.error;
        }

        if (this.state.awaitingFullResults) {
            return <Card style={{margin: '1em', padding: '1em'}}>
                <Box display="flex" flexDirection="row">
                    <div><CircularProgress size="30px" /></div>
                    <div style={{padding: '0 15px'}}><p>Results still processing... </p></div>
                    <div><p><small>(Session started at {this.state.sessionStarted})</small></p></div>
                </Box>
            </Card>
        }

        if(this.state.result) {
            switch (this.state.result.templateId) {
                case 'DEFAULT_BLUE':
                    return  <Result
                        path={this.props.match.url}
                        result={this.state.result}
                        theme={defaultTheme}
                    />;
                case 'IELTSI_PIE':
                    return <IetlsiPie result={this.state.result}/>;
                default:
                    break;
            }
        }
        return <></>;
    }
}


ResultContainer.propTypes = {
    match: PropTypes.object,
};

export default ResultContainer;
