import React, {Component} from "react";

import './Accordion.scss';
import {
    ExpandMore } from '@material-ui/icons';

import {
    Collapse } from '@material-ui/core';

import {withRouter} from 'react-router-dom';
import {CreateGoogleAnalyticsEvents, FireGoogleAnalyticsEvent} from '../lib/googleAnalytics';

class Accordion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isActive: false
        };

        CreateGoogleAnalyticsEvents('create', 'UA-31827-129', 'auto');
        this.session = this.props.match.params.session;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.itemNum !== this.props.itemNum) {
            this.setState({
                isActive: false
            })
        }

        if(this.props.finishedContent) {
            if(prevProps.finishedContent !== this.props.finishedContent) {
                this.setState({
                    isActive: false
                })
            }
        }

    }

    handleAccordionButton = (title, correct) => {

        let whatImprove = 'incorrect';
        let pageName = 'Results';

        if(correct) {
            whatImprove = 'correct';
        }

        if(!this.session) {
            pageName = 'Landing';
            this.session = 'no session';
            whatImprove = 'null'
        }

        FireGoogleAnalyticsEvent('send', {
            hitType: 'event',
            eventCategory: `${pageName} - Accordion`,
            eventAction: `Click - ${this.session}`,
            eventLabel: `${title} - ${whatImprove} - open: ${!this.state.isActive}`
        });

        this.setState({
            isActive: !this.state.isActive
        })
    };

    render() {
        let content = null;
        let color = null

        if(this.props.content) {
            content = <p dangerouslySetInnerHTML={{__html: this.props.content}}/>
        }

        if(this.props.blueTitle) {
            color = '#0C7CB9'
        }

        return (
            <div className={'accordion'}>
                <button onClick={() => this.handleAccordionButton(this.props.title, this.props.correct)} className={this.state.isActive ? '' : 'inactiveButton'} style={{ color: color }}>
                    {this.props.title}
                    <ExpandMore className={this.state.isActive ? 'expendOpen' : 'expend'}/>
                </button>
                <Collapse in={this.state.isActive} className={'active'}>
                    {/*<p dangerouslySetInnerHTML={{__html: this.props.content}}/>*/}
                    {content}
                    {this.props.children}
                </Collapse>
            </div>
        )
    }
}

export default withRouter(Accordion);