import React, {Component} from "react";
import HorizontalBarChart from "../components/HorizontalBarChart";
import SelectCarousel from "../components/SelectCarousel";

class SubskillVisualisation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentSubskill: this.props.subskills[0]
        };
    }

    updateSubSkillBar = (activeCount) => {
        this.setState({
            currentSubskill: this.props.subskills[activeCount]
        });

        this.changeLabelStyle(this.props.id, activeCount);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.subskills !== this.props.subskills) {
            this.setState({
                currentSubskill: this.props.subskills[0]
            })
        }

        if(prevProps.skillname !== this.props.skillname) {
            this.changeLabelStyle(this.props.id, 0);
        }
    }

    changeLabelStyle = (labelId, subskillIndex) => {
        console.log(labelId);
        document.getElementById(labelId).childNodes.forEach(li => {
            li.style.cssText = "font-weight: 500; padding-bottom: 4px; border-bottom: none; cursor: pointer"
        });
        document.getElementById(`${this.props.id}-${this.props.subskills[subskillIndex].name}`).style.cssText = "font-weight: bold; padding-bottom: 4px; border-bottom: 2px solid #333; cursor: pointer"
    };

    componentDidMount() {
        document.getElementById(`${this.props.id}-${this.props.subskills[0].name}`).style.cssText = "font-weight: bold; padding-bottom: 4px; border-bottom: 2px solid #333; cursor: pointer"
    }

    findSelectedSkill = (subskills, selectedSkillName) => {
        return subskills.findIndex(item => item.name === selectedSkillName)
    };

    render() {
        if(this.props.subskills) {
            return (
                <section style={{ borderTop: '1px solid white', marginTop: '32px'}}>
                    <ul
                        id={this.props.id}
                        style={{ listStyle: 'none', display: 'flex', justifyContent: 'space-evenly', padding: '16px 0' }}
                    >
                        {this.props.subskills.map(subskill => (
                                <li
                                    style={{cursor: 'pointer'}}
                                    key={subskill.name}
                                    onClick={() => {
                                        this.updateSubSkillBar(this.findSelectedSkill(this.props.subskills, subskill.name))
                                    }}
                                    id={`${this.props.id}-${subskill.name}`}>{subskill.name.toUpperCase()}

                                </li>
                        ))}
                    </ul>
                    <HorizontalBarChart currentSelectedSubskill={this.state.currentSubskill} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '32px'}}>
                        <SelectCarousel
                            subSkillCount={this.props.subskills.length}
                            updateCallback={this.updateSubSkillBar}
                            currentSkillName={this.props.skillname}
                            externalSubskillController={this.findSelectedSkill(this.props.subskills, this.state.currentSubskill.name)}
                        />
                    </div>
                </section>
            )
        } else {
            return null
        }
    }
}

export default SubskillVisualisation;