import React from "react";
import {Box} from "@material-ui/core";



const ExamFrame = (props) => {
    document.querySelector('body').className = 'IeltsiResults';
    const IELTSI_LEARNOSITY_URL = process.env.REACT_APP_IELTSI_LEARNOSITY_URL;
    let session;

    window.dataLayer.push({
        'event': 'pageview',
        'pageUrl': window.location.pathname,
        'pageTitle': 'IELTSI EXAMFRAME'
    });

    const handlePostMessage = event => {
        event.preventDefault();

        if(!event.data.examComplete) {
            return;
        }

        session = event.data.sessionId;
        props.history.push(`/score/${session}`);
        // console.log('yodal', event.data);

    };

    window.addEventListener('message', handlePostMessage, false);


    return (
        <>
            <header style={{width: '100%', height: '66px', background: '#8625FF'}}/>
            <Box maxWidth={'sm'} height={'calc(100% - 66px)'}>
                <iframe title={'exam'} width={'100%'} src={IELTSI_LEARNOSITY_URL}/>
            </Box>
        </>
    )
};

export default ExamFrame;