import React from "react";
import VerticalBarChart from "../components/VerticalBarChart";
import SubskillVisualisation from "./SubskillVisualisation";

const BasicVisualisation = props => {
    if(props.skill) {
        return (
            <>
                <VerticalBarChart results={props.skill} maxScale={props.maxScale} />
                <SubskillVisualisation subskills={props.skill.subskills} skillname={props.skill.name} id={'basicSubskill'}/>
            </>
        )
    } else {
        return 'loading'
    }

};

export default BasicVisualisation;