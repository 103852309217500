import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../diagnostic-components/src/components/layout/Layout';
import LoadingSpinner from '../../diagnostic-components/src/components/info/LoadingSpinner';

const LoadingLayout = ({
    title,
    to
}) => (
    <Layout
        icon="arrow_back"
        title={title}
        to={to}
    >
        <LoadingSpinner fadeIn="none" />
    </Layout>
);

LoadingLayout.propTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string
};

export default LoadingLayout;
