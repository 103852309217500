import React from 'react';


export function IeltsiHeader(props) {
    return <header style={{width: '100%', height: '66px', background: '#8625FF'}} />
}

export function IeltsiFooter(props) {

    return null; // Nothing here yet
}