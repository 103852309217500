import { combineReducers } from 'redux';
import app from './AppReducers';
import auth from './AuthReducers';

const rootReducer = combineReducers({
    app,
    auth
});

export default rootReducer;
