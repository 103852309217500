// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './DetailPopup.scss';

/**
 * A simple pop-up to display additional info or detail. Pass the raw HTML to display
 * in the *content* prop.
 */
const DetailPopup = ({ content, onClose }) => (
    <div className='detail-popup' onClick={onClose}>
        <button className='close-button'>{`\u2715`}</button>
        <div className='detail-popup-content' dangerouslySetInnerHTML={{__html: `<p>${content}</p>` }} />
    </div>
);

DetailPopup.propTypes = {
    /** the content to displayed in the pop-up (HTML) */
    content: PropTypes.string.isRequired,
    /** function to run when pop-up is clicked */
    onClose: PropTypes.func.isRequired
};

export default DetailPopup;
