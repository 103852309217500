import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './ReviewQuestion.scss';

const ReviewQuestion = (props) => {
    const {question} = props;
    
    const context = question.context ? question.context.replace(/&amp;/g, '&') : '';
    const explanation = question.explanation ? question.explanation.replace(/&amp;/g, '&') : '';

    return (
        <section className="review-question">
            <div>
                {question.inputText && 
                    <div className='inputText' dangerouslySetInnerHTML={{__html:question.inputText}} />
                }
            </div>
            <ul className="slides">
                <p dangerouslySetInnerHTML={{__html: context}} />
                <li hidden={props.answerHidden && 'hidden'}>
                    {question.correct ? (
                        <Fragment>
                            <small className="correct">Correct (Your answer)</small>
                            <p className="correct" dangerouslySetInnerHTML={{__html: question.givenAnswer}} />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <small className="incorrect">Incorrect (Your answer)</small>
                            { question.givenAnswer !== null ? (
                                <p className="incorrect" dangerouslySetInnerHTML={{__html: question.givenAnswer}} />
                            ) : (
                                <p className="incorrect">
                                    (You didn&apos;t answer this question)
                                </p>
                            )}
                            <small className="correct">Correct answer</small>
                            <p className="correct" dangerouslySetInnerHTML={{__html: question.correctAnswer}} />
                        </Fragment>
                    )}

                    <section className="explanation" dangerouslySetInnerHTML={{__html: explanation}} />

                </li>

                <li dangerouslySetInnerHTML={{__html: question.inputText}} />
            </ul>
        </section>
    );
};

ReviewQuestion.propTypes = {
    answerHidden: PropTypes.bool.isRequired,
    question: PropTypes.shape({
        context: PropTypes.string,
        correct: PropTypes.bool,
        correctAnswer: PropTypes.string,
        explanation: PropTypes.string,
        givenAnswer: PropTypes.string,
        inputText: PropTypes.string,
    }).isRequired
};

export default ReviewQuestion;
