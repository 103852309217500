import React, {Component} from "react";
import API from "../lib/api";
import {Container, Select, FormControl, MenuItem, createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import SelectOption from "../components/SelectOption";
import BasicVisualisation from "./BasicVisualisation";
import AdvancedVisualisation from "./AdvancedVisualisation";
import './Compass.scss';

import data from '../data/cohortDemo3';
import LollipopChart from "../components/LollipopChart";
import StudentList from "../components/StudentList";

//Chart Defaults
window.Chart.defaults.global.defaultFontFamily = 'Source Sans Pro, sans-serif';
window.Chart.defaults.global.defaultFontSize = 12;

const theme = createMuiTheme( {
    overrides: {
        MuiMenuItem: {
            root:{
                fontSize: '12px',
                fontWeight: 'bold'
            }
        }
    }
});


class Compass extends Component {

    constructor(){
        super();

        this.views = ['Bars', 'Density'];

        this.state = {
            ids: [],
            summary: {},
            selectedView: this.views[0]
        };

        document.querySelector('body').className = 'compass';

    }

    componentDidMount() {

        const highestCutoffCount = data.results.skills.reduce(
            (highestNumberSoFar, skill) => {
                const highestInSkill = Math.max(...skill.cutoffSummaries.map(cutoff => cutoff.count));
                return Math.max(highestInSkill, highestNumberSoFar);
            }, 0
        );

        if(window.location.search) {
            const ids = new URL(window.location.href).searchParams.get("ids").split(',');
            API.post('/cohort', {body: ids}).then(summary => {
                this.setState({
                    ids,
                    summary,
                    maxScale: highestCutoffCount,
                    subSkillCount: summary.results.skills[0].subskills.length,
                })
            })
        } else {
            this.setState({
                summary: data,
                maxScale: highestCutoffCount,
                subSkillCount: data.results.skills[0].subskills.length,
            });
        }
    }

    updateSkillBar = cat => {
        if(this.state.summary.results) {
            this.setState({
                currentSkill: this.state.summary.results.skills.filter(skill => skill.name === cat)[0]
            });
        }
    };

    handleViewControl = event => {
        this.setState({
            selectedView: event.target.value
        })
    };

    changeView = () => {
        if(this.state.selectedView === 'Bars') {
            return <BasicVisualisation skill={this.state.currentSkill} maxScale={this.state.maxScale}/>;
        } else {
            return <AdvancedVisualisation skills={this.state.summary.results.skills} currentSkill={this.state.currentSkill}/>
        }
    };

    render() {

        return (
            <>
                <header style={{background: '#fff', padding: '8px 0', display: 'flex'}}>
                    <Container maxWidth={'sm'}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'flex-end'}}>
                            <h3 style={{marginRight: '16px'}}>CHANGE VIEW: </h3>
                            <ThemeProvider theme={theme}>
                            <FormControl>
                                {
                                    this.views ? (
                                        <Select
                                            id={'view'}
                                            value={this.state.selectedView}
                                            onChange={this.handleViewControl}
                                        >
                                            {this.views.map(item => {
                                                return <MenuItem key={item} value={item}>{item.toUpperCase()}</MenuItem>
                                            })}
                                        </Select>
                                    ) : null
                                }
                            </FormControl>
                            </ThemeProvider>
                        </div>
                    </Container>
                </header>
                <Container maxWidth={'sm'}>
                    {
                        this.state.summary.results? (
                            <>

                                <SelectOption updateCallback={this.updateSkillBar} options={this.state.summary.results.skills}/>
                                {this.changeView()}
                                <section style={{ display: 'flex', flexDirection: 'column'}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <h2 style={{marginRight: '16px'}}>Questions</h2>
                                        <p style={{display: 'flex', alignItems: 'center'}}><span style={{display: 'inline-flex', width: '16px', height: '16px', backgroundColor: '#009FDA', borderRadius: '32px', margin: '0 16px'}}/>Incorrect</p>
                                        <p style={{display: 'flex', alignItems: 'center'}}><span style={{display: 'inline-flex', width: '16px', height: '16px', backgroundColor: '#89D8F5', borderRadius: '32px', margin: '0 16px'}}/>Correct</p>
                                    </div>
                                    {this.state.currentSkill ? <LollipopChart data={this.state.summary.questions} skill={this.state.currentSkill}/> : null}
                                </section>
                                {this.state.currentSkill ? <StudentList currentSkill={this.state.currentSkill}/> : null}

                            </>
                        ): 'loading'
                    }

                </Container>
            </>
        );
    }
}


export default Compass;