import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DetailPopup from './DetailPopup';
import Icon from './Icon';
import Intro from './Intro';

import './DetailFeedback.scss';

/**
 * Shows feedback with a pop-up detail view when clicking the info icon.
 */
const DetailFeedback = (props) => {
    const [showDetailPopup, setShowDetailPopup] = useState(false);

    const handleDetailClose = () => setShowDetailPopup(false);

    const handleDetailOpen = () => setShowDetailPopup(true);

    const {
        description,
        feedback,
        heading
    } = props;

    return (
        <div className="detail-feedback">
            {showDetailPopup &&
            <DetailPopup
                content={description}
                onClose={handleDetailClose}
            />}
            <span
                {...(description ? {
                    className: 'has-description',
                    onClick: handleDetailOpen
                } : {})}
            >
                <Icon icon="info" />
            </span>
            <Intro
                heading={heading}
                info={feedback}
            />
        </div>
    );
};

DetailFeedback.propTypes = {
    /** Detail description for pop-up (HTML). No pop-up will be displayed if this prop is omitted. */
    description: PropTypes.string,
    /** Heading (e.g. subskill name) (HTML) */
    heading: PropTypes.string,
    /** Feedback (HTML) */
    feedback: PropTypes.string
};

export default DetailFeedback;
