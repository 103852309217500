import React, { Component } from 'react';
import { Motion, spring } from 'react-motion';
import PropTypes from 'prop-types';

/**
 * A helper used to add motion to chart bars etc.
 *
 * The function passed as the *render* prop will be invoked repeatedly during the motion
 * phase and will receive a steadily increasing value. The *render* function should
 * return the component or element you'd like to animate; use the passed value to animate
 * its width/height etc.
 *
 * If *animate === false* then the *render* function will be called once, passing the *value* prop.
 *
 * Pass a function to *onRest* to do something when motion has finished.
 */
class MotionWrapper extends Component {
    componentDidMount() {
        const { animate, onRest } = this.props;

        if (!animate && onRest) {
            onRest();
        }
    }

    render() {
        const { animate, onRest, render, value } = this.props;

        return (
            animate
                ? (<Motion
                    defaultStyle={{value: 0}}
                    style={{value: spring(value)}}
                    onRest={onRest}
                >
                    {interpolatingStyle => render(interpolatingStyle.value)}
                </Motion>)
                : render(value)
        );
    }
}

MotionWrapper.defaultProps = {
    animate: true
};

MotionWrapper.propTypes = {
    /** should content animate */
    animate: PropTypes.bool,
    /** invoked when motion has finished */
    onRest: PropTypes.func,
    /** invoked while in motion, receives value, should render a component */
    render: PropTypes.func.isRequired,
    /** the final value to be passed to the 'render' function */
    value: PropTypes.number.isRequired
};

export default MotionWrapper;
