import API from './api';

/*
This function makes repeated requests for the complete results until the user gets bored and leaves.
*/
const INCOMPLETE_RESULTS_RETRY_DELAY = 5 * 1000; // five seconds

export default function GetResultsWithRetries(session, onComplete, onIncomplete = () => {}, onError = error => console.log(error)) {
    try {
        API.get(`/results/results/${session}`)
            .then(res => {
                if(res.resultsComplete) {

                    if(window.LogRocket) {
                        console.log('registering session reference with logrocket ' + res.sessionReference);
                        window.LogRocket.identify(res.sessionReference, {});
                    }

                    onComplete(res);
                } else {
                    console.log('Results incomplete - retrying for the good stuff. Session started - ' + res.created);
                    setTimeout(() => GetResultsWithRetries(session, onComplete, onIncomplete, onError), INCOMPLETE_RESULTS_RETRY_DELAY);
                    onIncomplete(res);
                }
            });
    } catch (error) {
        onError(error);
    }
}