function *idGenerator() {
    let i = 0;

    while(true) {
        yield i++;
    }
}

const id = idGenerator();

class Utils {
    static getUniqueId() {
        return id.next().value;
    }
}

export default Utils;
