// Core
import React from 'react';
import PropTypes from 'prop-types';
// Styles
import './NavItem.scss';
// Components
import { NavLink } from 'react-router-dom';
import Icon from '../info/Icon';

/** Renders a *NavLink* component if passed a *to* prop, otherwise renders an *a* element */
const NavItem = ({ action, icon, to, ...rest }) => (
    <React.Fragment>
        {to ? (
            <NavLink to={to}>
                {icon && (<Icon icon={icon} />)}
                {action}
            </NavLink>
        ) : (
            <a {...rest}>{action}</a>
        )}
    </React.Fragment>
);

NavItem.propTypes = {
    /** action for *NavLink* or *a* element */
    action: PropTypes.string,
    /** material-ui icon name for *NavLink* icon */
    icon: PropTypes.string,
    /** *to* prop for *NavLink* */
    to: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ])
};

export default NavItem;
