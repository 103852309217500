import React from 'react';
import PropTypes from 'prop-types';
import Utils from '../../lib/Utils';

import SelectionDots from '../widget/SelectionDots';

import './Pager.scss';

/**
 * A simple pager which wraps content and adds a *SelectionDots* component above or below.
 *
 * Won't render *SelectionDots* if only a single title is provided.
 */
const Pager = ({
    children,
    onPageChange,
    selectedPage,
    pagerAboveContent,
    titles
}) => {
    const selectionDots = titles.length > 1 &&
        (
            <SelectionDots
                name={`pager-dots-${Utils.getUniqueId()}`}
                onSelectionChange={onPageChange}
                selectedIndex={selectedPage}
                titles={titles}
            />
        );

    return (
        <div className="pager">
            {pagerAboveContent && selectionDots}
            <div className="pager-content">{children}</div>
            {!pagerAboveContent && selectionDots}
        </div>

    );
};

Pager.defaultProps = {
    pagerAboveContent: false
};

Pager.propTypes = {
    /** content for the page */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    /** onPageChange handler */
    onPageChange: PropTypes.func,
    /** whether to display the *SelectionDots* above the page content */
    pagerAboveContent: PropTypes.bool,
    /** which page is selected */
    selectedPage: PropTypes.number.isRequired,
    /** an array of title attributes for the pager's *SelectionDots* */
    titles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Pager;
