import React from 'react';
import PropTypes from 'prop-types';

import './LoginForm.scss';

/** Renders a user login form. */
const LoginForm = ({
    onBlur,
    onChange,
    onSubmit,
    isEnabled,
    username,
    password,
    loginErrorText,
    usernameErrorText,
    passwordErrorText,
}) => {
    return (
        <form className="login-form" onSubmit={onSubmit}>

            <label className="login-label" htmlFor="username">Username</label>
            <input
                className="login-input"
                id="username"
                name="username"
                onBlur={onBlur}
                onChange={onChange}
                type="text"
                value={username}
            />

            <p className="login-text-danger">{usernameErrorText}</p>

            <label className="login-label" htmlFor="password">Password</label>
            <input
                className="login-input"
                id="password"
                name="password"
                onBlur={onBlur}
                onChange={onChange}
                type="password"
                value={password}
            />

            <p className="login-text-danger">{passwordErrorText}</p>

            <button className="login-button" disabled={!isEnabled}>Log in</button>

            {loginErrorText && <div className="login-text-danger">{loginErrorText}</div>}
        </form>
    );
};

LoginForm.propTypes = {
    /** *onBlur* handler for username and password fields */
    onBlur: PropTypes.func,
    /** *onChange* handler for username and password fields */
    onChange: PropTypes.func,
    /** *onSubmit* handler */
    onSubmit: PropTypes.func,
    /** whether the login button is enabled */
    isEnabled: PropTypes.bool,
    /** username value */
    username: PropTypes.string,
    /** password value */
    password: PropTypes.string,
    /** text to display below submit button in case of error submitting form etc. */
    loginErrorText: PropTypes.string,
    /** text to display below username field if there are validation errors etc. */
    usernameErrorText: PropTypes.string,
    /** text to display below password field if there are validation errors etc. */
    passwordErrorText: PropTypes.string
};

export default LoginForm;
