import React from "react";
import DensityChart from "../components/DensityChart";
import AdvancedSubskillVisualisation from "./AdvancedSubskillVisualisation";

const AdvancedVisualisation = (props) => {
    if(Object.keys(props.currentSkill).length !== 0) {
        return (
            <section style={{marginTop: '32px'}}>
                <DensityChart currentSkill={props.currentSkill} skills={props.skills} id={'cohortSkillDensityChart'}/>
                <AdvancedSubskillVisualisation subskills={props.currentSkill.subskills} skillname={props.currentSkill.name} id={'advancedSubskill'}/>
            </section>
        )
    }

    return 'loading';

};

export default AdvancedVisualisation;