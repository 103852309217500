import React, {Component} from "react";

class LollipopChart extends Component {

    constructor(props) {
        super(props);
    }

    reDrawChart = () => {

        this.data = this.props.data.filter((obj) => obj.skill === this.props.skill.name);

        const chartParentWidth = document.getElementById('lollipopQuestionChart').offsetWidth;

        const margin = {top: 10, right: 30, bottom: 30, left: 30};
        const width = chartParentWidth - margin.left - margin.right;
        const height = this.data.length * (28 + 16);

        const svg = window.d3.select("#lollipopQuestionChart")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        const x = window.d3.scaleLinear()
            .domain([-35, 30])
            .range([0, width]);

        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(window.d3.axisBottom(x))
            .attr('opacity', '0');

        // Y axis
        const y = window.d3.scaleBand()
            .range([0, height])
            .domain(this.data.map(function (d) {
                return d.questionNumber;
            }))
            .padding(2);

        svg.append("g")
            .call(window.d3.axisLeft(y));

        // Lines
        svg.selectAll("myline")
            .data(this.data)
            .enter()
            .append("line")
            .attr("x1", function (d) {
                return x(-Math.abs(d.incorrectCount));
            })
            .attr("x2", function (d) {
                return x(d.correctCount);
            })
            .attr("y1", function (d) {
                return y(d.questionNumber);
            })
            .attr("y2", function (d) {
                return y(d.questionNumber);
            })
            .attr("stroke", "grey")
            .attr("stroke-width", "1px");

        // Circles of variable 1
        svg.selectAll("mycircle")
            .data(this.data)
            .enter()
            .append("circle")
            .attr("cx", function (d) {
                return x(d.correctCount)
            }) //x(d.value1);
            .attr("cy", function (d) {
                return y(d.questionNumber)
            }) //y(d.group);
            .attr("r", "14")
            .style("fill", "#89D8F5")
            .append('text')
            .attr("dx", function(d){return 0})
            .text(function(d){return d.questionNumber})
            .attr('fill', 'white')
            .attr('x', function (d) {
                return x(d.correctCount)
            })
            .attr('y', function (d) {
                return y(d.questionNumber)
            });

        svg.selectAll('xNumbersLabels')
            .data(this.data)
            .enter()
            .append('text')
            .attr("dx", function(d){return 0})
            .text(function(d){return d.correctCount})
            .attr('fill', '#2C2C2C')
            .attr('x', function (d) {
                return x(d.correctCount)
            })
            .attr('y', function (d) {
                return y(d.questionNumber) + 5
            })
            .attr('text-anchor', 'middle');

        // // Circles of variable 2
        svg.selectAll("mycircle")
            .data(this.data)
            .enter()
            .append("circle")
            .attr("cx", function (d) {
                return x(-Math.abs(d.incorrectCount));
            })
            .attr("cy", function (d) {
                return y(d.questionNumber);
            })
            .attr("r", "14")
            .style("fill", "#009FDA");

        svg.selectAll('yNumbersLabels')
            .data(this.data)
            .enter()
            .append('text')
            .attr("dx", function(d){return 0})
            .text(function(d){return d.incorrectCount})
            .attr('fill', 'white')
            .attr('x', function (d) {
                return x(-Math.abs(d.incorrectCount))
            })
            .attr('y', function (d) {
                return y(d.questionNumber) + 5
            })
            .attr('text-anchor', 'middle');
    };

    componentDidMount() {
        this.reDrawChart();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.skill !== this.props.skill) {
            document.getElementById('lollipopQuestionChart').firstChild.remove();
            this.reDrawChart();
        }
    }

    render() {
        return <div id="lollipopQuestionChart"/>
    }
}

export default LollipopChart;