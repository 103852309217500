import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import './Login.scss';

import {
    Card,
    Icon,
    Layout,
    LoginForm
} from '../diagnostic-components';

import Joi from 'joi-browser';
import {loginUser} from '../actions/AuthActions';
import {TITLE_PREFIX} from '../Constants';

/**
 * Login component
 */
class Login extends Component {
    constructor(props) {
        super(props);

        this.schema = {
            username: Joi.string().label('Username').trim().required(), // eslint-disable-line newline-per-chained-call
            password: Joi.string().label('Password').trim().required()
        };

        const login = {username: '', password: ''};
        const errors = this.validate(login);

        this.state = {
            login,
            errors,
            unknownError: false,
            touched: {username: false, password: false}
        };
    }

    componentDidUpdate(prevProps) {
        document.title = TITLE_PREFIX + 'Login';
        const errors = {...this.state.errors};
        const errorString = 'Username or password incorrect.';
        const remoteError = this.props.remoteError;
        let unknownError = false;

        if (JSON.stringify(prevProps.remoteError) === JSON.stringify(this.props.remoteError)) {
            return;
        }

        if (this.props.remoteError) {
            if (remoteError.response && remoteError.response.status === 401) {
                errors.remoteError = errorString;
            } else {
                errors.remoteError = null;
                unknownError = true;
            }
        } else {
            errors.remoteError = null;
        }

        this.setState({
            errors,
            unknownError
        });
    }

    hasErrors = () => {
        return Object
            .keys(this.state.errors)
            .filter((key) => !!this.state.errors[key])
            .length > 0;
    };

    validate = value => {
        const validation = Joi.validate(value, this.schema, {abortEarly: false});
        const errors = {};

        if (validation.error && validation.error.details) {
            validation.error.details.forEach((key) => {
                errors[key.path] = key.message;
            });
        }

        return errors;
    };

    onChange = event => {
        const login = Object.assign({}, this.state.login);

        login[event.target.name] = event.target.value;

        const errors = this.validate(login);

        return this.setState({login, errors});
    };

    onBlur = event => {
        const touched = Object.assign({}, this.state.touched);

        touched[event.target.name] = true;
        this.setState({touched});
    };

    onLogin = event => {
        event.preventDefault();
        this.props.onLogin(this.state.login);
    };

    render() {
        const {from} = this.props.location.state || {from: {pathname: '/'}};
        const {
            hasErrors,
            onBlur,
            onChange,
            onLogin,
            props: {
                isAuthenticated,
                isFetching
            },
            state: {
                errors,
                login,
                touched
            }
        } = this;

        const usernameErrorText = touched.username && errors.username;
        const passwordErrorText = touched.password && errors.password;

        let loginErrorText;

        if (errors.remoteError && !isFetching) {
            loginErrorText = errors.remoteError;
        }

        if (this.state.unknownError && !isFetching) {
            loginErrorText = 'An error has occurred, please try again later.';
        }

        return (isAuthenticated ?
            <Redirect to={from} />
            :
            <Layout title={'Log in'}>
                <div className="user-login">
                    <Card>
                        <div className="icon-wrapper">
                            <Icon icon="person" />
                        </div>
                        <LoginForm
                            onBlur={onBlur}
                            onChange={onChange}
                            onSubmit={onLogin}
                            isEnabled={!isFetching && !hasErrors()}
                            username={login.username}
                            password={login.password}
                            {...(usernameErrorText ? { usernameErrorText } : {})}
                            {...(passwordErrorText ? { passwordErrorText } : {})}
                            loginErrorText={loginErrorText}
                        />
                    </Card>
                </div>
            </Layout>
        );
    }
}

Login.propTypes = {
    location: PropTypes.object,
    onLogin: PropTypes.func,
    isFetching: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    remoteError: PropTypes.object
};

const mapStateToProps = state => ({
    isFetching: state.auth.isFetching,
    isAuthenticated: !!state.auth.user,
    remoteError: state.auth.error
});

const mapDispatchToProps = dispatch => ({
    onLogin: (login) => {
        dispatch(loginUser(login));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
