import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import './Dot.scss';

/**
 * *Dot* components wrap an *input* element. They can be used to select pages, items etc.
 *
 * The *key* and *value* props of the input element take their values from the *index* prop.
 */
class Dot extends PureComponent {
    render() {
        const {
            checked,
            index,
            name,
            onChange,
            title
        } = this.props;

        return (
            <input
                checked={checked}
                className="dot"
                key={index}
                name={name}
                onChange={onChange}
                type="radio"
                title={title}
                value={index}
            />
        );
    }
}

Dot.propTypes = {
    /** whether the input is checked */
    checked: PropTypes.bool,
    /** the key & value for the input */
    index: PropTypes.number.isRequired,
    /** name attribute for the input */
    name: PropTypes.string.isRequired,
    /** onChange handler */
    onChange: PropTypes.func,
    /** title attribute for the input */
    title: PropTypes.string.isRequired
};

export default Dot;
