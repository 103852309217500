import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import SelectionArrow from './SelectionArrow';

/**
 * Wraps child elements in a left/right pair of *SelectionArrow* components.
 */

class SelectionArrows extends PureComponent {
    render() {
        const {
            children,
            nextTitle,
            previousTitle,
            onClickPrevious,
            onClickNext
        } = this.props;

        return (
            <React.Fragment>
                <SelectionArrow arrowType="left" title={previousTitle} onClick={onClickPrevious} />
                {children}
                <SelectionArrow arrowType="right" title={nextTitle} onClick={onClickNext} />
            </React.Fragment>
        );
    }
}

SelectionArrows.defaultProps = {
    previousTitle: 'Previous',
    nextTitle: 'Next'
};

SelectionArrows.propTypes = {
    /** child elements */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    /** title attribute for *previous* arrow */
    previousTitle: PropTypes.string,
    /** title attribute for *next* arrow */
    nextTitle: PropTypes.string,
    /** onClick handler for *previous* arrow */
    onClickPrevious: PropTypes.func,
    /** onClick handler for *next* arrow */
    onClickNext: PropTypes.func
};

export default SelectionArrows;
