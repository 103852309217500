import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DetailPopup from './DetailPopup';
import Icon from './Icon';
import Intro from './Intro';
import NavItem from '../navigation/NavItem';

import './Feedback.scss';

/**
 * Shows feedback for particular subskill, with a pop-up detail view when clicking the info icon.
 */
const Feedback = (props) => {
    const [showDetailPopup, setShowDetailPopup] = useState(false);

    const handleDetailClose = () => setShowDetailPopup(false);

    const handleDetailOpen = () => setShowDetailPopup(true);

    const {
        answersLinkText,
        answersLinkTo,
        description,
        feedback,
        heading,
        showInfoIcon,
        variant
    } = props;

    const classNames = variant ? `feedback ${variant}` : 'feedback';

    return (
        <div className={classNames}>
            {showDetailPopup &&
            <DetailPopup
                content={description}
                onClose={handleDetailClose}
            />}
            {showInfoIcon &&
            <span
                {...(description ? {
                    className: 'has-description',
                    onClick: handleDetailOpen
                } : {})}
            >
                <Icon icon="info" />
            </span>}
            <Intro
                heading={heading}
                info={feedback}
            />
            {answersLinkTo &&
            <div className="answers-link">
                <NavItem
                    to={answersLinkTo}
                    action={answersLinkText}
                />
            </div>
            }
        </div>
    );
};

Feedback.defaultProps = {
    answersLinkText: 'VIEW ANSWERS',
    showInfoIcon: false
};

Feedback.propTypes = {
    /** text for answers link */
    answersLinkText: PropTypes.string,
    /** *to* prop for answers link *NavItem*. If this prop is omitted no link will be rendered. */
    answersLinkTo: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    /** Subskill description for pop-up (HTML). No pop-up or icon will be displayed if this prop is omitted. */
    description: PropTypes.string,
    /** Feedback for the subskill (HTML) */
    feedback: PropTypes.string,
    /** Heading (e.g. subskill name) (HTML) */
    heading: PropTypes.string,
    /** whether to show the info icon in the header */
    showInfoIcon: PropTypes.bool,
    /** variant class(es) to add to class list */
    variant: PropTypes.string
};

export default Feedback;
