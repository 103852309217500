import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { logoutUser } from '../actions/AuthActions';

/**
 * Logout component
 */
class Logout extends Component {
    componentDidMount() {
        this.props.onLogout();
    }

    render() {
        return (
            <Redirect to="/login" />
        );
    }
}

Logout.propTypes = {
    onLogout: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    onLogout: () => {
        dispatch(logoutUser());
    }
});

export default connect(null, mapDispatchToProps)(Logout);
