import React from "react";
import { withRouter } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Accordion from "../components/Accordion";
import {CreateGoogleAnalyticsEvents, FireGoogleAnalyticsEvent} from '../lib/googleAnalytics';
import uuidv4 from 'uuid/v4';

const examOptions = [
    process.env.REACT_APP_IELTSI_LEARNOSITY_URL, // 21 ITEM TEST
    process.env.REACT_APP_IELTSI_LEARNOSITY_14 // 14 ITEM TEST
];

function setUpSessionReference() {
    const session = uuidv4();
    if(window.LogRocket) {
        window.LogRocket.identify(session, {});
    }
    return session;
}

const PreTestIntro = (props) => {
    CreateGoogleAnalyticsEvents();
    const sessionReference = setUpSessionReference();
    document.querySelector('body').className = 'IeltsiResults';

    const dateTime = new Date().toUTCString();

    window.dataLayer.push({
        'event': 'pageview',
        'pageUrl': window.location.pathname,
        'pageTitle': 'IELTSI PRETEST'
    });

    FireGoogleAnalyticsEvent('send', 'pageview');

    const handleButtonClick = () => {

        const leavingDateTime = new Date().toUTCString();

        FireGoogleAnalyticsEvent('send', {
            hitType: 'event',
            eventCategory: 'Landing Page',
            eventAction: `Time`,
            eventLabel: `Landed: ${dateTime}, Left: ${leavingDateTime}`
        });

        window.location.replace(examOptions[0] + '?sessionId=' + sessionReference);
    };

    return (
        <>
            <header style={{width: '100%', height: '66px', background: '#8625FF'}}/>
            <Container maxWidth={'sm'}>
                <section style={{ margin: '24px 0', display: 'flex', flexDirection: 'column'}}>

                    <h1 style={{color: '#707070'}}>IELTS Intelligence</h1>
                    <p>You are going to take a reading test. You will get feedback on three different reading skills. Each of these skills are important in the IELTS test.</p>
                    <p>The three skills are:</p>
                    <Accordion title={'Finding details'} content={null}>
                        <p>When a task involves finding details, you have to locate one piece of information in the text. You need to understand what sort of information is needed, and find this in the text. To do this, you may have to look at more than one sentence, and understand how the text is organised.</p>
                        <p>Finding details is a skill you need for lots of IELTS tasks, including notetaking, sentence completion, and giving short answers to questions.</p>
                    </Accordion>

                    <Accordion title={'Understanding sentences'} content={null}>
                        <p>To understand sentences you have to get the writer’s meaning from the words, phrases and grammar they use, and also from the way the ideas are linked together in the sentence and between the sentences. Understanding sentences is a skill you need for lots of IELTS tasks, such as True/False/Not Given, Yes/No/Not Given and some Matching tasks.</p>
                    </Accordion>


                    <Accordion title={'Identifying main ideas'} content={null}>
                        <p>When a task involves identifying main ideas, you have to think about the main point the writer is making in a text or part of a text. To do these tasks you need to recognise how ideas relate to one another – for example, the relationship between a generalisation and an example. You may also need to think about why the writer is giving information or opinions.</p>
                        <p>Identifying main ideas is a skill you need for lots of IELTS tasks, including matching headings and multiple choice.</p>
                    </Accordion>
                    {/*<a  className={'regButton'} href={currentExam}>Tap to continue</a>*/}
                    <button className={'regButton'} onClick={() => handleButtonClick(props)}>Tap to continue</button>
                </section>
            </Container>
        </>
    )
};


export default withRouter(PreTestIntro);