import React, {Component} from 'react';
import './SkillHeaderMenu.scss';

export class SkillHeaderMenu extends Component {

    render() {
        return <div id="skillHeader" className="listParent">
                    {this.props.subskills.map(subskill => {
                        const className = 'menuItem ' + ( (subskill === this.props.selectedSubskill) ? 'selected' : '' );
                        return <div onClick={() => this.props.onSelectionChange(subskill)} className={className} key={subskill.name}>{subskill.name}</div>
                    })}
            </div>;
    }
}