import React, { useState } from 'react';
import Nav from './Nav';
import NavItem from './NavItem';
import PropTypes from 'prop-types';

/** A navigation menu created by wrapping an array of *NavItem* components in a *Nav*. */
const NavMenu = (props) => {
    const [hidden, setHidden] = useState(true);

    const handleClick = () => setHidden(!hidden);

    return (
        <React.Fragment>
            <Nav
                label="results navigation"
                icon="menu"
                menu="Menu"
                hidden={hidden}
                onClick={handleClick}
                popup={false}
            >
                {props.navItems.map((menuItem, index) => (
                    <NavItem
                        to={menuItem.to}
                        key={index}
                        action={menuItem.action}
                    />
                ))}
            </Nav>
        </React.Fragment>
    );
};

NavMenu.propTypes = {
    /** array of items to be rendered within the *Nav* component */
    navItems: PropTypes.arrayOf(PropTypes.shape({
        to: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired
    })).isRequired
};

export default NavMenu;
