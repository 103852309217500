import React, {Component} from 'react';

import {Swipeable} from 'react-swipeable';
import {withRouter} from 'react-router-dom';
import {CreateGoogleAnalyticsEvents, FireGoogleAnalyticsEvent} from '../../lib/googleAnalytics';

const activeSlice = 'rgb(134, 37, 255)';
const passiveSlice = 'rgb(59, 58, 58)';
const borderColour = 'rgba(255, 255, 255, 1)';

const chartScaleMinimumAdd = 30;
const rotateSegmentDurationSeconds = 0.8;

/*
    A spie chart, or polar area chart - height is percentage achieved.
*/
class TastySpie extends Component {

    lastSwipeWasLeft = null;
    currentChart = null; // The chart itself is managed outside of react's state which is a little awkward

    static getReorderedArrayStartingWith(array, desiredStart) {
        const index = array.indexOf(desiredStart);
        const slicedFromFront = array.slice(0, index);
        const slicedFromBack = array.slice(index);
        return slicedFromBack.concat(slicedFromFront);
    }

    rotateChartToSelect = (desiredSubskill) => {
        const newArray = TastySpie.getReorderedArrayStartingWith(this.state.data, desiredSubskill);
        this.setState({data: newArray, subskill: desiredSubskill, rotating: false});

        // redraw the chart
        this.recreateChart(newArray);
    };

    componentDidMount() {
        this.recreateChart(this.state.data);
    }

    rotationDurationTimer = cssRotation => {
          this.setState({rotating: true, cssRotation});
          window.setTimeout(() => {
              const subskill = this.state.data.filter(skill => skill.label === this.props.subskill.name)[0];
              this.rotateChartToSelect(subskill);
          }, rotateSegmentDurationSeconds * 1000);
    };

    onChartClick = e => {
        const selectedElement = this.currentChart.getElementsAtEvent(e);
        if(selectedElement != null && selectedElement.length > 0) {
            const selectedSubskill = this.state.data[selectedElement[0]._index];

            FireGoogleAnalyticsEvent('send', {
                hitType: 'event',
                eventCategory: 'SpieChart',
                eventAction: `Click - ${this.session}` ,
                eventLabel: selectedSubskill.label
            });

            // opens modal on segment click
            this.props.onSegmentClick(selectedSubskill.subskill);
        }
    };

    constructor(props) {
        super(props);

        // Display data is an array of objects with label, value and subskill properties, ordered so it starts with the 'firstSelectedSubskill' provided
        // The purpose for the reordering is to put the largest segment as the selected one at the bottom, otherwise it looks wonky
        const displayData = TastySpie.getReorderedArrayStartingWith(this.props.subskills, this.props.subskill)
            .map( (subskill, i) =>
                ({label: subskill.displayName, subskill, value: subskill.percentageResult + chartScaleMinimumAdd})
        );

        console.log(this.props);
        this.state = {
            data: displayData,
            rotating: false,
            cssRotation: null
        };

        CreateGoogleAnalyticsEvents();
        this.session = this.props.match.params.session;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // updates chart on subskill click
        if(prevProps.subskill !== this.props.subskill) {

            const fromPosition = this.props.subskills.indexOf(prevProps.subskill);
            const toPosition = this.props.subskills.indexOf(this.props.subskill);
            const arraySize = this.props.subskills.length;

            const lastSwipeWasLeft = this.lastSwipeWasLeft;
            this.lastSwipeWasLeft = null;

            // Two item arrays mean that the from and to are 1 apart no matter what, so we want to pick the direction that was swiped
            if(arraySize === 2 && lastSwipeWasLeft != null) {
                this.rotationDurationTimer(lastSwipeWasLeft ? -0.5 : 0.5);
                return;
            }

            // Amount of rotation and in which direction - negatives are anticlockwise, positives are clockwise
            const wrapRight = (arraySize - fromPosition) + toPosition;
            const wrapLeft = (toPosition - arraySize) - fromPosition;
            const noWrap = toPosition - fromPosition;

            // Determine the shortest route
            const goForIt = [noWrap, wrapRight, wrapLeft].reduce((acc, current) => Math.abs(acc) < Math.abs(current) ? acc : current);

            // Calculate as a fraction of 1, or apparently -1 because idk but it works
            const cssRotation = - 1 / arraySize * goForIt;

            console.log(JSON.stringify({wrapLeft, wrapRight, noWrap, cssRotation}));

            this.rotationDurationTimer(cssRotation);
        }
    }

    recreateChart(data) {
        const isFirstDraw = ! this.currentChart;

        if(! isFirstDraw) {
            // There is a pre-existing chart we need to destroy
            this.currentChart.destroy();
        }

        const labels = data.map(data => data.label);
        const values = data.map(data => data.value);

        const ctx = document.getElementById('resultsSpieChart').getContext('2d');

        const startAngle = this.props.subskills.length === 2 ? 0 : 0.5;

        this.currentChart = new window.Chart(ctx, {
            type: 'polarArea',
            data: {
                labels: labels,
                datasets: [{
                    data: values,
                    backgroundColor: [activeSlice, passiveSlice, passiveSlice],
                    borderColor: [borderColour, borderColour, borderColour],
                    borderWidth: '10'
                }]
            },
            options: Object.assign({
                animation: {
                    animateScale: isFirstDraw,
                    animateRotate: isFirstDraw,
                    duration: 1500
                },
                tooltips: {
                    enabled: false
                },
                startAngle, // places the first rather than the second value at the bottom
            }, suppressOtherChartComponentsSettings)
        });
    }

    swipedRight() {
        const {subskills, subskill, onSelectionChange} = this.props;
        this.lastSwipeWasLeft = false;
        const index = subskills.indexOf(subskill);
        if(index > 0) {
            onSelectionChange(subskills[index - 1]);
        } else {
            onSelectionChange(subskills[subskills.length - 1]);
        }
    }

    swipedLeft() {
        const {subskills, subskill, onSelectionChange} = this.props;
        this.lastSwipeWasLeft = true;
        const index = subskills.indexOf(subskill);
        if(index < (subskills.length - 1)) {
            onSelectionChange(subskills[index + 1]);
        } else {
            onSelectionChange(subskills[0]);
        }
    }

    render() {
        let rotationCanvasStyle = {};

        if(this.state.rotating) {
            rotationCanvasStyle.transitionDuration = `${rotateSegmentDurationSeconds}s`;
            rotationCanvasStyle.transform = `rotate(${this.state.cssRotation}turn)`;
        }

        return <Swipeable onSwipedRight={e => this.swipedRight()} onSwipedLeft={e => this.swipedLeft()}>
            {/*Note: Canvas height and width are a way of communicating a desired ratio of height/width to chartJS
                - they DO NOT set actual values - actual values are set through the style of the parent div */}
            <canvas style={rotationCanvasStyle} width="100" height="100" onClick={this.onChartClick} id="resultsSpieChart" />
        </Swipeable>
    }
}

const suppressOtherChartComponentsSettings = {
    legend: {
        display: false
    },
    axes: {
        display: false
    },
    scale: {
        display: false,
        ticks: {
            display: false,
            min: 0,
            max: 100 + chartScaleMinimumAdd
        }
    },
    title: {
        display: false
    },
    tooltip: {
        enabled: false
    }
};

export default withRouter(TastySpie);