import React, {Component} from "react";

import ExamAnswerScroller from "../components/ExamAnswerScroller";
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    TextField,
    Container,
    Fab, Radio, createMuiTheme, SnackbarContent
} from "@material-ui/core";
import {
    // ExpandMore,
    ArrowBack } from '@material-ui/icons';

import Accordion from "../components/Accordion";

import './IeltsiResults.scss';
import {ThemeProvider, withStyles} from "@material-ui/styles";
import GetResultsWithRetries from '../lib/resultsWithRetry';
import API from "../lib/api";
import Snackbar from "@material-ui/core/Snackbar";
import {FireGoogleAnalyticsEvent} from '../lib/googleAnalytics';

const theme = createMuiTheme({
    overrides: {
        MuiTextField: {
            root: {
                color: '#fff',
                background: '#fff',
                padding: '8px',
                flexGrow: 1,
                flex: '0 1 100%'
            }
        },
        MuiFilledInput: {
            multiline: {
                padding: '16px'
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '1.6rem'
            }
        },
        MuiFormGroup: {
            root: {
                flexDirection: 'row',
                justifyContent: 'space-evenly'
            }
        },
        MuiTypography: {
            body1: {
                fontSize: '1.6rem'
            }
        },
        MuiSnackbarContent: {
            message: {
                fontSize: '1.6rem'
            }
        }
    }
});

class IeltsiResults extends Component{

    constructor(props) {
        super(props);

        this.session = this.props.match.params.session;
        this.responses = null;
        this.results = null;

        this.state = {
            showAnswer: false,
            openSnackBar: false,
            snackbarMessage: 'Thank you for your feedback!',
            isHelpful: '',
            pollComments: '',
            finishedContent: null
        };

        document.querySelector('body').className = 'IeltsiResults';
    }

    componentDidMount() {

        window.dataLayer.push({
            'event': 'pageview',
            'pageUrl': window.location.pathname,
            'pageTitle': 'IELTSI SUBSKILL RESULTS'
        });

        GetResultsWithRetries(this.session, res => {
            this.responses = res.responses;
            this.results = this.responses.filter(item => item.subskill === this.props.match.params.skill);

            this.setState({
                itemNum: this.results[0].questionNumber,
            })
        });
    }

    updateFeedbackComponent = itemNum => {
        this.setState({
            itemNum: itemNum
        })
    };

    handleBackButton = () => {

        FireGoogleAnalyticsEvent('send', {
            hitType: 'event',
            eventCategory: 'Backbutton - Feedback',
            eventAction: 'Click',
            eventLabel: 'clicked'
        });

        console.log('find location', this.props);
        this.props.history.goBack();
    };

    handleShowMeButton = () => {
        this.setState({
            showAnswer: !this.state.showAnswer
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.itemNum !== this.state.itemNum) {
            this.setState({
                showAnswer: false,
                finishedContent: null
            })
        }
    }

    // TODO: Duplicated code between here and FeedbackPoll
    sendFeedback = event => {
        event.preventDefault();

        const formDataJson = {
            feedback: this.state.pollComments,
            wasThisHelpful: this.state.isHelpful,
            url: window.location.href,
            sessionId: this.props.match.params.session,
            screenSize: `${window.innerWidth} x ${window.innerHeight}`,
            device: window.navigator.userAgent
        };

        const payload = {
            body: formDataJson
        };

        console.log(event.target);

        try {
            API.post(`/prototype/minipoll-results/${this.session}`, payload)
                .then(() => {
                    console.log('Sent');

                    this.setState({
                        isHelpful: '',
                        pollComments: '',
                        finishedContent: true
                    });

                    window.setTimeout(() => {
                        this.setState({
                            openSnackBar: true
                        });

                        window.setTimeout(() => {
                            this.setState({openSnackBar: false});
                        }, 3000)
                    }, 1000);

                });
        } catch (error) {
            console.log(error);
        }


    };

    handleClickAccordion = (name) => {

        console.log(name);

        FireGoogleAnalyticsEvent('send', {
            hitType: 'event',
            eventCategory: 'Results - Accordion',
            eventAction: 'Click',
            eventLabel: 'Accordion name'
        });
    };

    handleRadioClick = event => {
        this.setState({ isHelpful: event.target.value});
        console.log('resolved', event.target.value);
    };

    render() {
        let questionFeedback;
        const lorem = 'Null';

        if(this.results) {
            questionFeedback = this.results.find(feedback => feedback.questionNumber === this.state.itemNum);
        } else {
            return null;
        }

        const answerColor = questionFeedback.correct ? 'answerCorrect' : 'answerIncorrect';
        const { classes } = this.props;

        const correctAnswer = () => {
            return (
                <>
                    {questionFeedback.context ? <p dangerouslySetInnerHTML={{__html: questionFeedback.context}}/> : <p>Null</p>}
                    <h2>Your answer</h2>
                    <p className={answerColor}>{questionFeedback.givenAnswer ? questionFeedback.givenAnswer : 'answer not given'}</p>
                    <Accordion title={'Why you were right'} content={questionFeedback.distractorLevelFeedback ? questionFeedback.distractorLevelFeedback : lorem} itemNum={this.state.itemNum}/>
                    <Accordion title={'How to improve'} content={questionFeedback.explanation ? questionFeedback.explanation : lorem} itemNum={this.state.itemNum} correct/>
                </>
            )
        };

        const incorrectAnswer = () => {
            return (
                <>
                    {questionFeedback.context ? <p dangerouslySetInnerHTML={{__html: questionFeedback.context}}/> : <p>Null</p>}
                    <h2>Your answer</h2>
                    <p className={answerColor}>{questionFeedback.givenAnswer ? questionFeedback.givenAnswer : 'answer not given'}</p>
                    {
                        questionFeedback.givenAnswer ? (
                            <Accordion title={'Why this was wrong'} content={questionFeedback.distractorLevelFeedback ? questionFeedback.distractorLevelFeedback : lorem} itemNum={this.state.itemNum}/>
                        ) : null
                    }
                    <Accordion title={'How to improve'} content={questionFeedback.explanation ? questionFeedback.explanation : lorem} itemNum={this.state.itemNum}/>
                    <Accordion title={'Try again'} content={questionFeedback.tryAgain ? questionFeedback.tryAgain : lorem} itemNum={this.state.itemNum}>
                        <h2>Correct Answer</h2>
                        <button onClick={this.handleShowMeButton}>{!this.state.showAnswer ? 'Show me the answer' : 'Hide the answer'}</button>
                        <p className={'answerCorrect'}>{this.state.showAnswer ? questionFeedback.correctAnswer : null}</p>
                    </Accordion>
                </>
            )
        };

        return (
            <>
                <header style={{width: '100%', height: '66px', background: '#8625FF'}}/>
                <ThemeProvider theme={theme}>
                    <Container maxWidth={'sm'}>
                        <section>
                            <Fab onClick={this.handleBackButton} className={classes.fab}><ArrowBack className={classes.arrowBack}/></Fab>
                            <p>Here are your answers to questions that tested your ability at <span>{questionFeedback.subskill}</span></p>
                            <ExamAnswerScroller results={this.results} selectedItem={0} updateCallback={this.updateFeedbackComponent}/>
                        </section>
                        <section style={{paddingBottom: '120px'}}>
                            <h2>Question {this.state.itemNum}</h2>
                            {!questionFeedback.correct ? incorrectAnswer() : correctAnswer()}
                            <Accordion title={'Was this feedback helpful?'} content={null} itemNum={this.state.itemNum} finishedContent={this.state.finishedContent} blueTitle>
                                    <form onSubmit={this.sendFeedback}>
                                        <FormControl id={'accordionForm'} component="div">
                                            <RadioGroup name={'wasThisHelpful'} value={this.state.isHelpful} onChange={this.handleRadioClick}>
                                                <FormControlLabel style={{color: '#707070', fontSize: '1.6rem'}} control={<Radio style={{color: '#707070', fontSize: '1.6rem'}} required />} label={'Yes'} value={'true'}/>
                                                <FormControlLabel style={{color: '#707070', fontSize: '1.6rem'}} control={<Radio style={{color: '#707070', fontSize: '1.6rem'}} required/>} label={'No'} value={'false'}/>
                                            </RadioGroup>

                                            <TextField
                                                multiline
                                                placeholder={'(Optional) Please let us know how we can provide even better feedback.'}
                                                rows="4"
                                                margin="normal"
                                                name={'feedback'}
                                                style={{width: '100%', padding: 0}}
                                                variant={'filled'}
                                                onChange={event => this.setState({ pollComments: event.target.value})}
                                                value={this.state.pollComments}
                                            />
                                            <button style={{color: '#0C7CB9', cursor: 'pointer', alignSelf: 'flex-end'}}>Submit</button>

                                        </FormControl>
                                    </form>
                            </Accordion>
                        </section>
                    </Container>

                    <Snackbar open={this.state.openSnackBar}>
                        <SnackbarContent
                            message={'Thank you for your feedback!'}
                        />
                    </Snackbar>
            </ThemeProvider>
            </>
        )
    }
}

const styles = {
    fab: {
        background: '#fff',
        width: '36px',
        height: '36px',
        margin: '16px'
    },
    arrowBack: {
        fontSize: '2.4rem'
    }
};

export default withStyles(styles)(IeltsiResults);