import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {colorPropType} from '../../propTypes';

const ScoreChartLegendBar = ({ x, y, width, height, color }) => (
    <Fragment>
        <defs>
            <linearGradient id="barGradient">
                <stop offset="0%" stopColor={color.low} />
                <stop offset="100%" stopColor={color.high} />
            </linearGradient>
        </defs>
        <g transform={`translate(${x},${y})`}>
            <rect
                width={width}
                height={height}
                rx="0"
                ry="0"
                fill="url(#barGradient)"
            />
        </g>
    </Fragment>
);


ScoreChartLegendBar.propTypes = {
    color: colorPropType,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default ScoreChartLegendBar;
