// Core
import React from 'react';
import PropTypes from 'prop-types';
// Styles
import './Layout.scss';
// Components
import Banner from './Banner';

/**
 * Renders a *Banner* component and wraps child elements in a *ul* element.
 */
const Layout = ({ children, title, to, action, icon, variant }) => (
    <React.Fragment>
        <Banner title={title} icon={icon} to={to} action={action} />
        <ul className={variant}>
            {children}
        </ul>
    </React.Fragment>
);

Layout.defaultProps = {
    variant: 'page start'
};

Layout.propTypes = {
    /** text for banner *NavItem* */
    action: PropTypes.string,
    /** child elements */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    /** name of the icon to display in the top-left **/
    icon: PropTypes.string,
    /** header title for Banner component */
    title: PropTypes.string.isRequired,
    /** location for the banner *NavItem* */
    to: PropTypes.string,
    /** className for the *ul* element */
    variant: PropTypes.string
};

export default Layout;
