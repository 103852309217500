import API from '../lib/api';

import {
    REQUEST_CURRENT_USER,
    ERROR_CURRENT_USER,
    RECEIVE_CURRENT_USER,
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS,
    LOG_IN_ERROR,
    LOGOUT
} from '../actions/AuthActions';

const DEFAULT_AUTH_STATE = {
    isFetching: false,
    token: null,
    user: null
};

/**
 * Reducer for 'auth' state.
 *
 * @param {Object} state - current auth state
 * @param {Object} action - Action
 *
 * @return {Object} - new state
 */
export default function auth(state = DEFAULT_AUTH_STATE, action) {
    switch (action.type) {
        case REQUEST_CURRENT_USER:
            return {
                ...state,
                isFetching: true
            };

        case ERROR_CURRENT_USER:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        case RECEIVE_CURRENT_USER:
            return {
                ...state,
                isFetching: false,
                user: action.user
            };

        case LOG_IN_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case LOG_IN_SUCCESS:
            localStorage.setItem('authToken', action.token);
            API.authToken = localStorage.getItem('authToken');

            return {
                ...state,
                isFetching: false,
                token: action.token,
                user: action.user
            };

        case LOG_IN_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        case LOGOUT:
            localStorage.removeItem('authToken');
            API.authToken = null;

            return {
                ...state,
                isFetching: false,
                token: null,
                user: null
            };

        default:
            return state;
    }
}
