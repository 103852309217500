import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import ScoreChartAxisTick from './ScoreChartAxisTick';
import ScoreChartBarFuzzy from './ScoreChartBarFuzzy';
import { interpolateRgb } from 'd3-interpolate';
import PropTypes from 'prop-types';

import './ScoreChart.scss';
import {colorPropType, cutoffsPropType} from '../../propTypes';

/**
 * An animated chart to display a user's skill score. The chart would usually be
 * contained by a *ScoreChartCard* component.
 */
class ScoreChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            percentageResult: 0
        };
    }

    refCallback = (ref) => {
        if (ref) {
            this.elementWidth = ref.getBoundingClientRect().width;
        }
    };

    fuzzyBar = (props) => {
        return (<ScoreChartBarFuzzy
            {...props}
            fuzziness={this.props.fuzziness}
            maxWidth={this.elementWidth}
        />);
    };

    updatePercentageResult = () => {
        const { props } = this;

        window.setTimeout(() => {
            this.setState({
                percentageResult: props.percentageResult
            });

            if (props.onAnimationFinished) {
                props.onAnimationFinished(props.percentageResult);
            }

        }, props.animationDelay);
    };

    componentDidMount() {
        this.updatePercentageResult();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.percentageResult !== this.props.percentageResult) {
            this.updatePercentageResult();
        }
    }

    render() {
        const {
            props,
            state
        } = this;

        const theme = {
            axis: {
                ticks: {
                    text: {
                        fontSize: 12,
                        fill: 'rgb(44, 44, 44)'
                    }
                }
            }
        };

        const tickValues = props.cutoff ? [0, props.cutoff.value, 100] : [0, 100];

        return <div ref={ref => this.refCallback(ref)} className="score-chart-container">
            <ResponsiveBar
                data={[
                    {
                        'result': 'AD',
                        'percentageResult': state.percentageResult
                    }
                ]}
                keys={[
                    'percentageResult'
                ]}
                indexBy="result"
                margin={{
                    'top': 0,
                    'right': 0,
                    'bottom': 13,
                    'left': 0
                }}
                padding={0.3}
                colors={
                    [
                        // TODO: use standalone function for interpolation rather than bringing in d3-interpolate
                        interpolateRgb(props.color.low, props.color.high)(this.state.percentageResult / 100)
                    ]
                }
                indexValue={null}
                colorBy="id"
                defs={[]}
                fill={[]}
                height={36}
                borderColor="inherit:darker(1.6)"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    'tickSize': 8,
                    'tickPadding': 2,
                    'tickRotation': 0,
                    'renderTick': (props) =>
                        <ScoreChartAxisTick
                            {...props}
                            cutoff={this.props.cutoff}
                        />,
                    'tickValues': tickValues
                }}
                axisLeft={null}
                labelTextColor="inherit:darker(1.6)"
                animate={true}
                motionStiffness={300}
                motionDamping={21}
                layout="horizontal"
                minValue={0}
                maxValue={100}
                enableGridY={false}
                enableLabel={false}
                theme={theme}
                isInteractive={false}
                {...(props.barComponent
                    ? { barComponent: props.barComponent }
                    : (props.isFuzzy ? { barComponent: this.fuzzyBar } : {}))
                }
            />
        </div>;
    }
}

ScoreChart.defaultProps = {
    animationDelay: 200,
    fuzziness: 15,
    isFuzzy: true
};

ScoreChart.propTypes = {
    /** delay in milliseconds before animation of the bar begins */
    animationDelay: PropTypes.number,
    /** an optional custom *Bar* component */
    barComponent: PropTypes.func,
    /** colour range for chart bar */
    color: colorPropType,
    /** cutoff used to display a label and dot below chart indicating the cutoff point */
    cutoff: cutoffsPropType,
    /**
     * size of the ending fade to transparent, in pixels;
     * ignored if a custom *Bar* component is provided */
    fuzziness: PropTypes.number,
    /**
     * whether to add a fade to transparent to the right side of the bar;
     * ignored if a custom *Bar* component is provided
     */
    isFuzzy: PropTypes.bool,
    /** invoked when a new percentageResult has been passed to the component and animation has finished */
    onAnimationFinished: PropTypes.func,
    /** percentage result to display */
    percentageResult: PropTypes.number.isRequired
};

export default ScoreChart;
