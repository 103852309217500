import React, {Component} from "react";

import refToNames from '../data/sessionRefsToNames';

class StudentList extends Component {

    constructor(props) {
        super(props);
        this.studentData = [];
        this.studentData = this.ContructCohortData(this.props.currentSkill, refToNames);
}

    ContructCohortData = (skill, nameMap) => {
        const studentMap = {};

        skill.cutoffSummaries.forEach(cutoff => {
            const cutoffValue = cutoff.cutoff ? cutoff.cutoff : "BELOW";

            cutoff.sessionReferences.forEach(studentRef => {
                const studentDetails = {};
                studentMap[studentRef] = studentDetails;
                studentDetails.name = nameMap[studentRef];
                studentDetails.cutoffs = [];

                studentDetails.cutoffs.push({skillName: skill.name + ' overall', cutoffValue});
            });
        });

        skill.subskills.forEach(subskill => {
            subskill.cutoffSummaries.forEach(cutoff => {
                const cutoffValue = cutoff.cutoff ? cutoff.cutoff : "BELOW";
                cutoff.sessionReferences.forEach(studentRef => {
                    const studentDetails = studentMap[studentRef];
                    studentDetails.cutoffs.push({
                        skillName: subskill.name, cutoffValue
                    });
                });
            });
        });

        return studentMap;
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.currentSkill !== this.props.currentSkill) {
            this.studentData = this.ContructCohortData(this.props.currentSkill, refToNames);
        }
    }

    createTableEntry = () => {

        let children = [];
        for(let prop in refToNames){
            let cutoffElem = [];

            cutoffElem.push(this.studentData[prop].cutoffs.map((cutoff, index) => {
                switch(cutoff.cutoffValue) {
                    case 'BELOW':
                        return <td key={cutoff.cutoffValue + index} style={{ color: '#0799D8', fontWeight: 'bold'}}>{cutoff.cutoffValue}</td>;
                    case 'ON TARGET':
                        return <td key={cutoff.cutoffValue + index} style={{ color: '#BE2FAD', fontWeight: 'bold'}}>{cutoff.cutoffValue}</td>;
                    case 'ABOVE':
                        return <td key={cutoff.cutoffValue + index} style={{ color: '#2F07CB', fontWeight: 'bold'}}>{cutoff.cutoffValue}</td>
                }

            }));

            children.push(
                <tr key={prop}>
                    <td key={this.studentData[prop].name}>{this.studentData[prop].name}</td>
                    {cutoffElem}
                </tr>
            )
        }

        return children;
    };

    render() {
        return (
            <>
                <h2 style={{padding: '32px 0'}}>Student List</h2>
                <table style={{width: '100%'}}>
                    <tbody>
                        <tr>
                            <th/>
                            <th>{`${this.props.currentSkill.name} overall`}</th>
                            {this.props.currentSkill.subskills.map(subskill => <th key={subskill.name}>{subskill.name}</th>)}
                        </tr>
                        {this.createTableEntry()}
                    </tbody>
                </table>
            </>
        )
    }

}

export default  StudentList;