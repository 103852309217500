import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from '../info/Icon';

import './ReviewNav.scss';

/**
 * A navigation header allowing the user to choose a question to review.
 */
const ReviewNav = (props) => {
    const displayQuestion = props.questionIndex + 1;

    return (
        <ol className="review-nav">

            <li>
                { props.questionIndex === 0 ? (
                    // eslint-disable-next-line
                    <a className="inactive">
                        <Icon icon="keyboard_arrow_left" />Previous
                    </a>
                ) : (
                    <Link to={`${displayQuestion - 1}`}>
                        <Icon icon="keyboard_arrow_left" />Previous
                    </Link>
                )}
            </li>

            <li>
                {displayQuestion} / {props.questionCount}
            </li>

            <li>
                { props.questionIndex === props.questionCount - 1 ? (
                    /* eslint-disable-next-line */
                    <a className="inactive">
                        Next<Icon icon="keyboard_arrow_right" />
                    </a>
                ) : (
                    <Link to={`${displayQuestion + 1}`}>
                        Next<Icon icon="keyboard_arrow_right" />
                    </Link>
                )}
            </li>

        </ol>
    );
};

ReviewNav.propTypes = {
    /** total number of questions to navigate */
    questionCount: PropTypes.number.isRequired,
    /** current question index */
    questionIndex: PropTypes.number.isRequired
};

export default ReviewNav;
