import React from 'react';

import PreTestIntro from "../../pages/PreTestIntro";
import IeErrorPage from "../../pages/IeErrorPage";
import Compass from "../../pages/Compass";
/**
 * Home component
 */
const Home = () => {

    if (window.MSCompatibleInfo != null) {
        return <IeErrorPage/>
    }

    return <Compass/>;
};

export default Home;

