import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';

import './LoadingSpinner.scss';

/**
 * A simple loading spinner component. Use as a placeholder when awaiting content etc.
 *
 * See [react-spinkit on github](https://github.com/KyleAMathews/react-spinkit)
 * for further info on the *fadeIn* and *name* props.
 */
const LoadingSpinner = ({ fadeIn, name }) => <Spinner fadeIn={fadeIn} name={name} />;

LoadingSpinner.defaultProps = {
    fadeIn: 'half',
    name: 'three-bounce'
};

LoadingSpinner.propTypes = {
    /** spinner fade-in behavior */
    fadeIn: PropTypes.string,
    /** the spinner to use */
    name: PropTypes.string
};

export default LoadingSpinner;
