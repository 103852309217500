import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Styles
import './LearnerSkill.scss';

// Components
import {
    Card,
    CutoffChart,
    DetailFeedback,
    Feedback,
    Pager,
    skillResultPropType,
    themePropType
} from '../diagnostic-components';

class LearnerSkill extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSubskillIndex: 0
        };
    }

    handleSelectionChange = (index) => {
        this.setState({
            selectedSubskillIndex: index
        }, () => this.scrollIntoView());
    };

    scrollIntoView = () => {
        this.node.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest'});
    };

    componentDidMount() {
        this.scrollIntoView();
    }

    render() {
        const {props, state} = this;

        const subskillResults = props.skillResult.subskills;

        // It's best to pass a key={selectedSkill} prop to this component to ensure it's
        // re-instantiated when the selected skill changes, otherwise it would be possible to have a
        // selectedSubskillIndex greater than the length of the subskill results array. The line below
        // prevents this situation.
        const index = state.selectedSubskillIndex < subskillResults.length
            ? state.selectedSubskillIndex
            : 0;

        let subskillResult;

        if (subskillResults.length > 0) {
            subskillResult = subskillResults[index];
        }

        return (
            <div ref={node => this.node = node} className="learner-skill">
                <Card>
                    <Feedback
                        answersLinkTo={{
                            pathname: `${props.path}/${props.skillResult.name}/1`,
                        }}
                        feedback={props.skillResult.feedbackText}
                        heading={`<h3>${props.skillResult.displayName}</h3>`}
                    />
                    {subskillResults.length > 0 &&
                        <section className="subskill">
                            <Pager
                                selectedPage={index}
                                titles={subskillResults.map(ssr => ssr.displayName)}
                                onPageChange={this.handleSelectionChange}
                            >
                                <div className="chart-and-info">
                                    <CutoffChart
                                        barLabel={subskillResult.displayName}
                                        color={props.theme.scoreDisplay.color}
                                        cutoff={subskillResult.cutoffs[0]}
                                        percentageResult={subskillResult.percentageResult}
                                    />
                                    <DetailFeedback
                                        description={subskillResult.description}
                                        feedback={subskillResult.feedbackText}
                                        heading={`<h3>${subskillResult.displayName}</h3>`}
                                    />
                                </div>
                            </Pager>
                        </section>
                    }
                </Card>
            </div>
        );
    }
}

LearnerSkill.propTypes = {
    skillResult: skillResultPropType,
    level: PropTypes.string,
    path: PropTypes.string,
    theme: themePropType
};

export default LearnerSkill;
