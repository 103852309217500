import React, { Component } from "react";
import {
    RadioGroup,
    FormLabel,
    FormControl,
    FormControlLabel,
    Radio,
    TextField,
    createMuiTheme,
    SnackbarContent
} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
import { ThemeProvider } from "@material-ui/styles";
import Snackbar from '@material-ui/core/Snackbar';
import API from "../lib/api";

const theme = createMuiTheme({
    overrides: {
        MuiTextField: {
            root: {
                color: '#fff',
                background: '#fff',
                padding: '8px',
                flexGrow: 1,
                flex: '0 1 100%'
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '1.6rem'
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: 0
                },
                '&:hover:not(.Mui-disabled):before': {
                    borderBottom: '0!important'
                },
                '&:after': {
                    borderBottom: 0
                }
            }
        },
        MuiTypography: {
            body1: {
                fontSize: '1.6rem'
            }
        },
        MuiSnackbarContent: {
            message: {
                fontSize: '1.6rem'
            }
        }
    }
});

class FeedbackPoll extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openSnackBar: false,
            feedback: '',
            isHelpful: ''
        };

        this.radioBox = document.querySelector('#radioBox');
        this.session = this.props.match.params.session;
    }

    updatePollIsHelpful = event => {
        this.setState({isHelpful: event.target.value});
        const feedbackBox = document.querySelector('#feedbackBox');
        feedbackBox.style.transform = 'translate(0,-100%)';
    };

    //send data to api
    sendFeedback = event => {
        event.preventDefault();

        const formDataJson = {
            feedback: this.state.feedback,
            wasThisHelpful: this.state.isHelpful,
            url: window.location.href,
            sessionId: this.props.match.params.session,
            screenSize: `${window.innerWidth} x ${window.innerHeight}`,
            device: window.navigator.userAgent
        };

        const payload = {
            body: formDataJson
        };

        try {
            API.post(`/prototype/minipoll-results/${this.session}`, payload)
                .then(res => {
                    console.log(res);

                    const miniPoll = document.querySelector('#miniPollContainer');
                    miniPoll.style.visibility = 'hidden';
                    miniPoll.style.opacity = '0';

                    window.setTimeout(() => {
                        this.setState({openSnackBar: true});

                        window.setTimeout(() => {
                            this.setState({openSnackBar: false});
                        }, 3000)
                    }, 1000);

                });
        } catch (error) {
            console.log(error);
        }

    };

    componentDidMount() {
        const feedbackBox = document.querySelector('#feedbackBox');
        const radioBox = document.querySelector('#radioBox');
        const container = document.querySelector('#miniPollContainer');
        console.log(feedbackBox);
        feedbackBox.style.bottom = `-${feedbackBox.offsetHeight}px`;
        container.style.height = radioBox.offsetHeight;
        container.style.overflow = 'hidden';
    }

    render() {
        return (
            <>
                <ThemeProvider theme={theme}>
                    <section id={'miniPollContainer'} style={{transition: 'opacity 600ms, visibility 600ms', visibility: 'visible', opacity: '1'}}>
                        <form onSubmit={this.sendFeedback}>
                            <FormControl id={'radioBox'} component="div">
                                <FormLabel style={{color: '#fff', fontSize: '1.6rem', fontWeight: 'bold'}}>Was this helpful?</FormLabel>
                                <RadioGroup name={'wasThisHelpful'} onChange={this.updatePollIsHelpful} value={this.state.isHelpful} style={{flexDirection: 'row', justifyContent: 'space-evenly', paddingTop: '16px'}}>
                                    <FormControlLabel style={{color: '#fff', fontSize: '1.6rem'}} control={<Radio style={{color: '#fff', fontSize: '1.6rem'}}/>} label={'YES'} value={'true'}/>
                                    <FormControlLabel style={{color: '#fff', fontSize: '1.6rem'}} control={<Radio style={{color: '#fff', fontSize: '1.6rem'}}/>} label={'NO'} value={'false'}/>
                                </RadioGroup>
                            </FormControl>
                            <div id='feedbackBox'>
                                <TextField
                                    multiline
                                    placeholder={'(Optional) Please let us know how we can provide even better feedback.'}
                                    rows="4"
                                    margin="normal"
                                    name={'feedback'}
                                    value={this.state.feedback}
                                    onChange={event => this.setState({feedback: event.target.value})}
                                />
                                <button style={{color: '#fff', cursor: 'pointer', alignSelf: 'flex-end'}}>Submit</button>
                            </div>
                        </form>
                    </section>
                    <Snackbar open={this.state.openSnackBar}>
                        <SnackbarContent
                            message={'Thank you for your feedback!'}
                        />
                    </Snackbar>
                </ThemeProvider>
            </>
        )
    };
}

export default withRouter(FeedbackPoll);