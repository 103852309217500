import React from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    Card,
    Layout,
    LoadingSpinner,
    navItemsPropType,
    NavMenu,
    resultPropType
} from '../diagnostic-components';

import './ExamSessions.scss';

const ExamSessions = (props) =>  (
    <div className="examSessions">
        <NavMenu navItems={props.navItems} location={props.url}/>
        <Layout title="Exam sessions">
            {!props.results
                ? <LoadingSpinner/>
                : (
                    <Card heading={'<h2>Choose a test</h2>'}>
                        <ul>
                            {props.results.map(({sessionId, examTitle}) => (
                                <li key={sessionId}>
                                    <Link to={`/results/${sessionId}`}>{examTitle}</Link>
                                </li>)
                            )}
                        </ul>
                    </Card>)
            }
        </Layout>
    </div>
);

ExamSessions.propTypes = {
    navItems: navItemsPropType.isRequired,
    results: PropTypes.arrayOf(resultPropType),
    url: PropTypes.string.isRequired
};

export default ExamSessions;
