import React from 'react';
import PropTypes from 'prop-types';
import { interpolateRgb } from 'd3-interpolate';
import CutoffBar from './CutoffBar';

import './CutoffPlot.scss';
import {colorPropType, cutoffsPropType} from '../../propTypes';

/**
 * Animated bar chart to display the percentage of cutoff.
 */
const CutoffPlot = ({
    animate,
    barLabel,
    barWidth,
    color,
    cutoff,
    fuzziness,
    percentageResult,
    width,
    height,
    isFuzzy
}) => {
    // TODO: tidy this up, in particular to be consistent about using absolute position x/y values or transforming position
    const topPadding = 15;

    const centre = width / 2;
    const barMaxHeight = height - 45;

    const cutoffLineX2 = width - 36;
    const cutoffLineY = barMaxHeight - (barMaxHeight / 100 * cutoff.value);

    const barColor = interpolateRgb(color.low, color.high)(percentageResult / 100);
    const barHeight = barMaxHeight / 100 * percentageResult;
    const barX = centre - barWidth / 2;

    const labelY = cutoffLineY + 4;
    const rightLabelX = width - 30;

    const lowerLabelY = barMaxHeight + 22;

    return (
        <svg
            className="cutoff-plot"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
        >
            <rect height={topPadding} width={width} opacity={0} />
            <g transform={`scale(1,-1) translate(0,-${barMaxHeight + topPadding})`}>
                <CutoffBar
                    animate={animate}
                    x={barX}
                    y={0}
                    color={barColor}
                    fuzziness={fuzziness}
                    width={barWidth}
                    height={barHeight}
                    maxHeight={barMaxHeight}
                    isFuzzy={isFuzzy}
                />
            </g>
            <g transform={`translate(8,${topPadding})`}>
                <text x="0" y={labelY}>{cutoff.name}</text>
                <text x={rightLabelX} y={labelY}>{cutoff.name}</text>
                <line
                    className="cutoff-line"
                    x1="22"
                    x2={cutoffLineX2}
                    y1={cutoffLineY}
                    y2={cutoffLineY}
                />
            </g>
            <text
                transform={`translate(${centre},${lowerLabelY + topPadding})`}
                textAnchor="middle"
            >
                {barLabel}
            </text>
        </svg>
    );
};

CutoffPlot.defaultProps = {
    barWidth: 88,
    fuzziness: 15,
    isFuzzy: false
};

CutoffPlot.propTypes = {
    /** whether to animate the bar */
    animate: PropTypes.bool,
    /** label displayed below the chart bar */
    barLabel: PropTypes.string,
    /** bar width */
    barWidth: PropTypes.number,
    /** defines colour range for chart bar */
    color: colorPropType,
    /** cutoff object */
    cutoff: cutoffsPropType,
    /**
     * size of the ending fade to transparent, in pixels;
     * ignored if a custom *Bar* component is provided */
    fuzziness: PropTypes.number,
    /**
     * whether to add a fade to transparent to the right side of the bar;
     * ignored if a custom *Bar* component is provided
     */
    isFuzzy: PropTypes.bool,
    /** percentage of cutoff to display */
    percentageResult: PropTypes.number,
    /** plot width */
    width: PropTypes.number,
    /** plot height */
    height: PropTypes.number
};

export default CutoffPlot;
