import React, {Component} from "react";
import {Container} from "@material-ui/core";
import GetResultsWithRetries from '../lib/resultsWithRetry';

class Score extends Component {

    constructor() {
        super();

        this.state = {
            result: null
        };

        document.querySelector('body').className = 'IeltsiResults';

    }

    componentDidMount() {
        window.dataLayer.push({
            'event': 'pageview',
            'pageUrl': window.location.pathname,
            'pageTitle': 'IELTSI SCORE'
        });

        const session = this.props.match.params.session;

        GetResultsWithRetries(session, res => {
            console.log(res);
            this.setState({
                result: res
            })
        });
    }

    handleOnClick = () => {
        const session = this.props.match.params.session;
        this.props.history.push(`/results/${session}`);
    };

    render() {

        let score;

        if(this.state.result) {
           score =  <p style={{ padding: '40px 0'}} id={'score'}>{`${this.state.result.overallResults.totalCorrect}/`}<span>{this.state.result.overallResults.maxPossibleTotalCorrect}</span></p>;
        }

        return (
            <>
                <header style={{width: '100%', height: '66px', background: '#8625FF'}}/>
                <Container maxWidth={'sm'}>
                    <section className={'score'}>
                        <h1 style={{ color: '#707070', textAlign: 'center', fontSize: '32px'}}>Well done for completing the test!</h1>
                        <p style={{ textAlign: 'center'}}>You scored</p>
                        {score}
                        <p>Want to know more? Get your personalised feedback report</p>
                        <ul>
                            <li>Understand why your answers were right or wrong</li>
                            <li>Learn about your strengths and weaknesses in reading</li>
                            <li>Improve your English for IELTS with tips and strategies</li>
                        </ul>
                    </section>
                    <button className={'regButton'} onClick={this.handleOnClick}>Show results</button>
                </Container>

            </>
        )


    }
}


export default Score;