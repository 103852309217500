import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import './SelectionArrow.scss';

/**
 * Renders a left or right arrow which can be used for paging, choosing items etc.
 */
class SelectionArrow extends PureComponent {
    render() {
        const { arrowType, onClick, title } = this.props;
        const leftArrowPath = 'M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z';
        const rightArrowPath = 'M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z';
        const d = arrowType === 'right' ? rightArrowPath : leftArrowPath;

        return (
            <div
                className="selection-arrow-wrapper"
                onClick={onClick}
                title={title}

            >
                <svg
                    className="selection-arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path className="selection-arrow-path" d={d} />
                    <path fill="none" d="M0 0h24v24H0V0z" />
                </svg>
            </div>
        );
    }
}

SelectionArrow.propTypes = {
    /** whether to render a left or right arrow */
    arrowType: PropTypes.oneOf(['left', 'right']).isRequired,
    /** onClick handler */
    onClick: PropTypes.func,
    /** the arrow's *title* attribute */
    title: PropTypes.string.isRequired
};

export default SelectionArrow;
