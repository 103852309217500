import React from 'react';
import PropTypes from 'prop-types';
import Intro from '../info/Intro';
import Feedback from '../info/Feedback';

import './OverviewCard.scss';

/**
 * Similar to the *Card* component, this adds an optional *View detail* link, an *onClick*
 * handler and an *active* class to indicate focus on the component.
 *
 * Designed to contain a *ScoreChart* component.
 */
const OverviewCard = (props) => {
    const handleClick = () => {
        if (props.active && props.feedback) {
            return props.onClick(null);
        }

        props.onClick(props.skill);
    };

    const {
        active,
        children,
        detailLinkCaption,
        heading,
        showDetailLink,
        lessCaption,
        moreCaption,
        feedback
    } = props;

    const hasFeedback = !!feedback;
    const cardClasses = `overview-card${hasFeedback ? ' has-feedback' : ''}${active ? ' active' : ''}`;
    const feedbackCaption = active ? lessCaption : moreCaption;

    return (
        <li className={cardClasses}>
            <div className="card" onClick={handleClick}>
                <Intro heading={heading} />
                <section>
                    {children}
                </section>
                {hasFeedback && <Feedback
                    answersLinkTo={feedback.answersLinkTo}
                    feedback={feedback.feedback}
                />}
                {showDetailLink &&
                <h4 className="detail-link">
                    {hasFeedback ? feedbackCaption : detailLinkCaption}
                </h4>}
            </div>
        </li>
    );
};

OverviewCard.defaultProps = {
    detailLinkCaption: 'View detail',
    showDetailLink: true,
    lessCaption: 'Show less',
    moreCaption: 'Show more'
};

OverviewCard.propTypes = {
    /** adds an *active* class to the component */
    active: PropTypes.bool,
    /** child elements */
    children: PropTypes.element.isRequired,
    /** detail link caption */
    detailLinkCaption: PropTypes.string,
    /**
     * Feedback to display if no detail results are included in *score*,
     * as well as link info for the *View answers* link */
    feedback: PropTypes.shape({
        feedback: PropTypes.string,
        answersLinkTo: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ])
    }),
    /** card heading */
    heading: PropTypes.string.isRequired,
    /** onClick handler */
    onClick: PropTypes.func,
    /** skill name, passed to the onClick handler */
    skill: PropTypes.string,
    /** whether to show the detail link */
    showDetailLink: PropTypes.bool,
    /** 'Show less' caption (for hiding feedback) */
    lessCaption: PropTypes.string,
    /** 'Show more' caption (for showing feedback) */
    moreCaption: PropTypes.string
};

export default OverviewCard;
