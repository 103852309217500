import React, {Component} from "react";
import kernel from "kernel-smooth";

const INCREMENT = 0.5;
function getXYCoordinates(percentageArray) {

    const bandwidth = kernel.silverman(percentageArray);
    // console.log(bandwidth);

    //(bandwidth > 0) ? bandwidth : 2

    // Call the function without a bandwidth in the event of an invalid bandwidth
    const estimator = kernel.density(percentageArray, kernel.fun.gaussian, (bandwidth > 0) ? bandwidth : 2 );

    // console.log((bandwidth > 0) ? bandwidth : 6);

    // Create an array going from -100 to 200 in increments
    const x = Array(...Array((500 / INCREMENT) + 1)).map((val, index) => (index * INCREMENT - 300));

    // console.log(x);
    const y = estimator(x); // Feed our array of x plot points to get their corresponding ys according to the calculated density
    const xyCoordinates = x.map((xPoint, index) => ({ x: xPoint, y: (y[index] * percentageArray.length) * 10  })); // Shift our two positional arrays into a single array of x/y objects

    const trimmedCoords = trimZeroCoords(xyCoordinates);
    // console.log(trimmedCoords);
    return trimmedCoords;
}

function trimZeroCoords(coordsArray) {

    let start, end;

    for(let i = 0; i < coordsArray.length; i++) {
        if(coordsArray[i].y > 0.01) {
            start = i;
            break;
        }
    }
    for(let i = coordsArray.length - 1; i >= 0; i--) {
        if(coordsArray[i].y > 0.01) {
            end = i;
            break;
        }
    }

    end = end || coordsArray.length - 1;
    return coordsArray.slice(start || 0, end +1)
}

class DensityChart extends Component {

    constructor(props) {
        super(props);

        this.data = props.currentSkill.percentages;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let anyChanges = false;
        if(prevProps.currentSkill !== this.props.currentSkill) {
            anyChanges = true;
            this.data = prevProps.currentSkill.percentages;
        }
        if(anyChanges) {
            this.reDrawChart();
        }
    }

    componentDidMount() {
        this.reDrawChart();
        window.plotter = () => getXYCoordinates(this.data);
    }

    componentWillUnmount() {
        window[this.props.id].destroy();
    }

    reDrawChart = () => {

        if(window[this.props.id] && window[this.props.id].destroy) {
            window[this.props.id].destroy();
        }

        this.chartData = {
            datasets: []
        };

        const ctx = document.getElementById(this.props.id).getContext('2d');
        ctx.canvas.style.letterSpacing = '1px';

        const gradientStrokeInactive = ctx.createLinearGradient(500, 0, 100, 0);
        gradientStrokeInactive.addColorStop(0, "rgba(214,214,214, 0)");
        gradientStrokeInactive.addColorStop(1, "rgb(167,163,165, 0)");

        const gradientFillInactive = ctx.createLinearGradient(500, 0, 100, 0);
        gradientFillInactive.addColorStop(0, "rgba(214,214,214, 0.16)");
        gradientFillInactive.addColorStop(1, "rgb(167,163,165, 0.16)");

        this.props.skills.forEach((item) => {
            if(this.props.currentSkill === item) {
                this.chartData.datasets.unshift({
                    data: getXYCoordinates(item.percentages),
                    showLine: true,
                    pointRadius: 0,
                    showLegend: false,
                    fill: true,
                })
            } else {
                this.chartData.datasets.push({
                    data: getXYCoordinates(item.percentages),
                    showLine: true,
                    pointRadius: 0,
                    showLegend: false,
                    fill: true,
                    backgroundColor: gradientFillInactive,
                    borderColor: gradientStrokeInactive
                })
            }

        });

        const minDataValue = Math.min(...this.chartData.datasets.map(item => item.data[0].x));
        const maxDataValue = Math.max(...this.chartData.datasets.map(item => item.data[item.data.length - 1].x));

        let lowestValue = minDataValue;
        const highestValue = maxDataValue;
        const cutoffs = [40, 70];

        const shuffleToZero = n => n - lowestValue;
        const percentMultiplier = 100 / shuffleToZero(highestValue);


        const convertToPercentile = n => {
            let shuffledToZero = shuffleToZero(n);
            const nConverted = percentMultiplier * shuffledToZero;
            return nConverted / 100;
        };

        // const lowestValueConverted = convertToPercentile(lowestValue); // Should be 0
        // const highestValueConverted = convertToPercentile(highestValue); // Should be 100
        // const zeroConverted = convertToPercentile(0); // Should be between 0 and +15.5
        // const hundredConverted = convertToPercentile(100);
        const cutoffsConverted = cutoffs.map(convertToPercentile);

        const recalculateGradientPlugin = {
            id: 'recalculateGradientPlugin',
            afterLayout: function(chart, options) {
                const scales = chart.scales;
                // create a linear gradient with the dimensions of the scale

                const color = chart.ctx.createLinearGradient(
                    scales["x-axis-1"].left,
                    0,
                    scales["x-axis-1"].right,
                    0
                );
                // add gradients stops
                color.addColorStop(0, "rgba(7, 153, 216, 0.9)");
                color.addColorStop(cutoffsConverted[0], "rgba(190, 47, 173, 0.9)");
                color.addColorStop(cutoffsConverted[1], "rgba(190, 47, 173, 0.9)");
                color.addColorStop(1, "rgba(47, 7, 203, 0.9)");

                // changes the background color option
                chart.data.datasets[0].backgroundColor = color;
                chart.data.datasets[0].borderColor = 'rgba(190, 47, 173, 0)'
            }
        };


        const halfway = (fromValue, toValue) => fromValue + ((toValue - fromValue) / 2);
        const pretendCutoffs = [{value: 40, name: 'ON TARGET'}, {value: 70, name: 'ABOVE'}];
        const cutoffLabels = [];

        cutoffLabels.push({value: 0, name: 'BELOW', halfway: halfway(minDataValue, pretendCutoffs[0].value)});
        for(let i = 0; i < pretendCutoffs.length; i++) {
            const cutoff = pretendCutoffs[i];
            if(i === pretendCutoffs.length - 1) {
                cutoffLabels.push(Object.assign({halfway: halfway(pretendCutoffs[pretendCutoffs.length - 1].value, maxDataValue)}, cutoff));
            } else {
                cutoffLabels.push(Object.assign({halfway: halfway(pretendCutoffs[i].value, pretendCutoffs[i + 1].value)}, cutoff));
            }
        }

        const options = {
            responsive: true,
            maintainAspectRatio: true,
            legend: {
                display: false,
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: false,
                        display: false,
                    },
                    gridLines: {
                        display: false,
                        color: "rgba(0, 0, 0, 0)",
                    },
                    position: 'right'
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: false,
                        autoSkip: false,
                        display: true,
                        maxRotation: 0,
                        minRotation: 0,
                        userCallback: function(value, index, values) {
                            const getNearest = (desired, actuals) => {
                                return actuals.reduce((acc, value) => Math.abs(desired - acc) > Math.abs(desired - value) ? value : acc, actuals[0]);
                            };

                            for(let i = 0; i < cutoffLabels.length; i++) {
                                const cutoff = cutoffLabels[i];

                                if(value === getNearest(cutoff.halfway, values)) {
                                    return cutoff.name;
                                }
                            }
                            return null;
                        },
                        min: minDataValue,
                        max: maxDataValue
                    },
                    gridLines: {
                        display: false
                    },
                }],
            },
            events: [],
            tooltips: {
                enabled: false
            }
        };

        window[this.props.id] = new window.Chart(ctx, {
            type: 'scatter',
            data: this.chartData,
            options: options,
            plugins: [recalculateGradientPlugin]
        });
    };

    render() {
        return <canvas width="500" height="250" id={this.props.id}/>
    }
}

export default  DensityChart;