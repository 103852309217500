import React from "react";
import DensityChart from "../components/DensityChart";
import SubskillVisualisation from "./SubskillVisualisation";
import SelectCarousel from "../components/SelectCarousel";

class AdvancedSubskillVisualisation extends SubskillVisualisation {

    render() {
        return (
            <section style={{ borderTop: '1px solid white', marginTop: '32px',}}>
                <ul
                    id={this.props.id}
                    style={{ listStyle: 'none', display: 'flex', justifyContent: 'space-evenly', padding: '16px 0' }}
                >
                    {this.props.subskills.map(subskill => (
                        <li
                            key={subskill.name}
                            onClick={() => {
                                this.updateSubSkillBar(this.findSelectedSkill(this.props.subskills, subskill.name))
                            }}
                            id={`${this.props.id}-${subskill.name}`}>{subskill.name.toUpperCase()}
                        </li>
                    ))}
                </ul>
                <DensityChart currentSkill={this.state.currentSubskill} skills={this.props.subskills} id={'cohortSubskillDensityChart'}/>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '32px'}}>
                    <SelectCarousel
                        subSkillCount={this.props.subskills.length}
                        updateCallback={this.updateSubSkillBar}
                        currentSkillName={this.props.skillname}
                        externalSubskillController={this.findSelectedSkill(this.props.subskills, this.state.currentSubskill.name)}
                    />
                </div>
            </section>
        )
    }

}

export default AdvancedSubskillVisualisation;