import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../info/Icon';

import './ReviewFooter.scss';

/**
 * A footer for question review, allowing the user to select to view
 * either the stimulus or the question text by clicking the arrow.
 */
const ReviewFooter = (props) => (
    <footer className="review-footer">
        {props.showStimulusLink ? (
            <ol>
                <li>
                    <span>Stimulus</span>
                </li>
                <li>
                    { /* eslint-disable-next-line */}
                    <a onClick={props.onClick}>
                        <Icon icon="keyboard_arrow_right" />
                    </a>
                </li>
            </ol>
        ) : (
            <ol>
                <li>
                    { /* eslint-disable-next-line */}
                    <a onClick={props.onClick}>
                        <Icon icon="keyboard_arrow_left" />
                    </a>
                </li>
                <li>
                    <span>Question</span>
                </li>
            </ol>
        )}
    </footer>
);

ReviewFooter.propTypes = {
    /** onClick handler */
    onClick: PropTypes.func.isRequired,
    /** whether to show the stimulus link (if false question link is shown) */
    showStimulusLink: PropTypes.bool.isRequired
};

export default ReviewFooter;
