import React, {Component} from "react";

class HorizontalBarChart extends Component {

    constructor(props) {
        super(props);

        this.barValues = this.props.currentSelectedSubskill.cutoffSummaries.map(item => item.count);
        this.biggestValue = Math.max(...this.barValues);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let anyChanges = false;
        if(prevProps.currentSelectedSubskill !== this.props.currentSelectedSubskill) {
            this.barValues = this.props.currentSelectedSubskill.cutoffSummaries.map(item => item.count);
            this.biggestValue = Math.max(...this.barValues);
            anyChanges = true;
        }
        if(anyChanges) {
            this.reDrawBarChart();
        }
    }

    componentDidMount() {
        this.reDrawBarChart();
    }

    reDrawBarChart = () => {
        const ctx = document.getElementById('horBarChart').getContext('2d');

        const data = {
            labels: ['BELOW', 'ON TARGET', 'ABOVE'],
            datasets: [{
                data: this.barValues,
                backgroundColor: [
                    'rgba(0, 159, 218, 1)',
                    'rgba(0, 159, 218, 1)',
                    'rgba(0, 159, 218, 1)'
                ],
                hoverBackgroundColor: [
                    'rgba(0, 159, 218, 1)',
                    'rgba(0, 159, 218, 1)',
                    'rgba(0, 159, 218, 1)'
                ],
            }]
        };

        const options = {
            responsive: true,
            legend: {
                display: false,
            },
            scales: {
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: this.biggestValue + (this.biggestValue * 0.2),
                        display: false
                    },
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                        display: false
                    },

                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                    },
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                        display: false
                    },
                    afterFit: function(scale) {
                        scale.height = 150;
                        scale.paddingTop = 0;
                    },
                    categoryPercentage: 0.5,
                    barPercentage: 1,

                    categorySpacing: 0
                }],
            },
            animation: {
                onComplete: function () {
                    const ctx = this.chart.ctx;

                    ctx.font = window.Chart.helpers.fontString(window.Chart.defaults.global.defaultFontFamily, 'normal', window.Chart.defaults.global.defaultFontFamily);
                    ctx.fillStyle = "#5A5A5A";
                    ctx.textAlign = 'top';
                    ctx.textBaseline = 'top';

                    this.data.datasets.forEach(dataset =>
                    {
                        for (let i = 0; i < dataset.data.length; i++) {
                            for(const key in dataset._meta)
                            {
                                const model = dataset._meta[key].data[i]._model;
                                ctx.fillText(dataset.data[i], model.x + 5, model.y - 5);
                            }
                        }
                    });
                }
            },
            events: []
        };

        this.myBarChart = new window.Chart(ctx, {
            type: 'horizontalBar',
            data: data,
            options: options,
        });
    };

    componentWillUnmount() {
        this.myBarChart.destroy();
    }

    render() {
        return <canvas width="100" height="50" id='horBarChart'/>;
    }
}

export default HorizontalBarChart;