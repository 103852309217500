import {
    APP_READY,
    APP_ERROR
} from '../actions/AppActions';

const DEFAULT_APP_STATE = {
    ready: false,
    error: null
};

/**
 * Reducer for 'app' state
 *
 * @param {Object} state - current state
 * @param {Object} action - Action
 *
 * @return {Object} - new state
 */
export default function app(state = DEFAULT_APP_STATE, action) {
    switch (action.type) {
        case APP_READY:
            return {
                ...state,
                ready: true,
                error: null
            };

        case APP_ERROR:
            return {
                ...state,
                ready: true,
                error: action.error
            };

        default:
            return state;
    }
}
