import React from 'react';

import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom';

import Error, {ErrorBoundary, genericNotFoundError} from '../pages/Error';
import ExamSessionsContainer from '../pages/ExamSessionsContainer';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import Home from '../components/routing/Home';
import PrivateRoute from '../components/routing/PrivateRoute';
import ResultContainer from '../pages/ResultContainer';
import ReviewContainer from '../pages/ReviewContainer';

import navItems from './navItems.json';
import IeltsiResults from "../pages/IeltsiResults";
import Score from "../pages/Score";
import ExamFrame from "../pages/ExamFrame";
import Compass from "../pages/Compass";
import Cohort from '../pages/Cohort';

/**
 * Router to display when the app is ready
 */
const AppRouter = () => (
    <BrowserRouter>
        <ErrorBoundary>
            <Switch>
                <Route
                    path="/login"
                    component={Login}
                />
                <Route
                    exact path="/results/:session"
                    component={ResultContainer}
                />
                <Route
                    exact path="/score/:session"
                    component={Score}
                />
                <Route
                    exact path="/exam"
                    component={ExamFrame}
                />
                <Route
                    exact path="/compass"
                    component={Compass}
                />
                {/*<Route*/}
                {/*    exact path="/prepay/:session"*/}
                {/*    component={PrePay}*/}
                {/*/>*/}
                <Route
                    exact path="/results/:session/:skill"
                    component={props => (
                        <IeltsiResults {...props} />
                    )}
                />
                <Route
                    exact path="/results/:session/:skill/:question"
                    component={props => (
                        <ReviewContainer
                            {...props}
                            resultsHomeUrl={`/results/${props.match.params.session}`}
                        />
                    )}
                />
                <Route
                    exact path="/cohort"
                    component={Cohort}
                />
                <Route
                    path="/"
                    component={Home}
                    exact
                />
                <PrivateRoute
                    component={Logout}
                    path="/logout"
                />
                <PrivateRoute
                    component={ExamSessionsContainer}
                    navItems={navItems}
                    path="/sessions"
                />
                <Route
                    path="/"
                    render={() => <Error error={genericNotFoundError} />}
                />
            </Switch>
        </ErrorBoundary>
    </BrowserRouter>
);

export default AppRouter;
