import React from 'react';
import PropTypes from 'prop-types';

function arrayToSentence(skillDisplayNames) {
    return [skillDisplayNames.slice(0, -1).join(', '), skillDisplayNames.pop()]
        .filter(w => w !== '').join(' or ');
}

/** Shows user instructions on how to select and view detailed results and feedback. */
const CallToAction = ({
    skillDisplayNames
}) => {
    const skillList = arrayToSentence(skillDisplayNames);

    return (
        <section className="call-to-action">
            <h2>Well done for completing the test.</h2>
            <p>
                <strong>Click on a skill ({`${skillList}`})</strong> to see your feedback,
                check your answers, and get advice on how you can improve.
            </p>
        </section>
    );
};

CallToAction.propTypes = {
    /** an array of skill display names to show in the call to action */
    skillDisplayNames: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CallToAction;
