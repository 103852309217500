const MAX_ATTEMPTS = 10;

/*
We've been getting widow.ga errors - this ensures we don't get those anymore, while minimising dropped events with retries.
 */

export function FireGoogleAnalyticsEvent(action, data, attempts=0) {
    if(! window.ga) {
        if(attempts === MAX_ATTEMPTS) {
            // giving up
            console.error('Giving up sending GA event - ' + action + ', no more retries, gave up after ' + attempts, data);
            return;
        }

        console.error('Unable to send GA event - ' + action + ', setting retry' + attempts, data);

        setTimeout(() => FireGoogleAnalyticsEvent(action, data, ++attempts), 1000);
        return;
    }

    window.ga(action, data);
}
export function CreateGoogleAnalyticsEvents(attempts = 0) {
    if(! window.ga) {
        if(attempts === MAX_ATTEMPTS) {
            // giving up
            console.error('Giving up creating GA event -  window.ga(\'create\', \'UA-31827-129\', \'auto\');' + attempts);
            return;
        }

        console.error('Unable to create GA events - setting retry -  window.ga(\'create\', \'UA-31827-129\', \'auto\');' + attempts);
        setTimeout(() => CreateGoogleAnalyticsEvents(++attempts), 1000);
        return;
    }
    window.ga('create', 'UA-31827-129', 'auto');
}