import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
        Check,
        Close,
        KeyboardArrowLeft,
        KeyboardArrowRight } from '@material-ui/icons';

import {
    Stepper,
    Step,
    StepLabel,
    createMuiTheme
} from '@material-ui/core';

import {withRouter} from 'react-router-dom';
import { ThemeProvider } from "@material-ui/styles";

import './ExamAnswerScroller.scss';
import {CreateGoogleAnalyticsEvents, FireGoogleAnalyticsEvent} from '../lib/googleAnalytics';

const theme = createMuiTheme({

    palette: {
        primary: {
            main: '#00A4A1'
        },
        secondary: {
            main: '#C81F66'
        }
    },

    overrides: {
        MuiStepper: {
            root: {
                padding: 0,
                overflow: 'hidden'
            }
        },
        MuiStepLabel: {
            label: {
                fontSize: '12px',
                fontWeight: '100',
                color: '#0C7CB9',
                '-webkit-text-stroke': '0.45px rgb(12, 124, 185, 0.5)',
                '&$active': {
                    color: '#0C7CB9',
                    fontWeight: 'bold',
                },
                '&$completed': {
                    color: '#0C7CB9',
                    fontWeight: '100',
                }

            }
        },
        MuiStep: {
            horizontal: {
                '&:last-child': {
                    paddingRight: '8px'
                }
            }
        }
    }
});

class ExamAnswerScroller extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeStep: this.props.selectedItem,
            questionNum: this.props.results[0].questionNumber,
            results: this.props.results
        };

        CreateGoogleAnalyticsEvents();
        this.session = this.props.match.params.session;

    }

    handleLeftClick = () => {
        if(this.state.activeStep > 0) {
            const updatedNextQ  = this.state.results[this.state.activeStep - 1].questionNumber;

            this.setState({
                activeStep: this.state.activeStep - 1,
                questionNum: updatedNextQ
            });

            const element = document.querySelector(`#question${updatedNextQ}`);
            element.scrollIntoView();
        }
    };

    handleRightClick = () => {

        if(this.state.activeStep < this.state.results.length - 1) {
            const updatedNextQ  = this.state.results[this.state.activeStep + 1].questionNumber;

            this.setState({
                activeStep: this.state.activeStep + 1,
                questionNum: updatedNextQ
            });

            const element = document.querySelector(`#question${updatedNextQ}`);
            element.scrollIntoView();
        }

    };

    handleManualClick = (questionNumber, itemCorrect) => {
        const newActiveStep = this.state.results.findIndex(item => item.questionNumber === questionNumber);

        console.log('clicked');

        FireGoogleAnalyticsEvent('send', {
            hitType: 'event',
            eventCategory: 'Question Item',
            eventAction: `Click - ${this.session}`,
            eventLabel: `${questionNumber} - ${itemCorrect}`
        });

        this.setState({
            activeStep: newActiveStep,
            questionNum: questionNumber
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.questionNum !== this.state.questionNum) {
            this.props.updateCallback(this.state.questionNum);
        }
    }

    render() {

        const stepperProps = {
            ...this.state,
            classes: this.props.classes,
            handleManualClick: this.handleManualClick
        };

        return (
            <div className='scroller'>
                <KeyboardArrowLeft onClick={this.handleLeftClick}/>
                <StepperLayout {...stepperProps}/>
                <KeyboardArrowRight onClick={this.handleRightClick}/>
            </div>
        )
    };
}

const StepperLayout = props => {

    const CheckIcon = props => {
        if(props.active) {
            return <Check color="primary" />
        }
        return  <Check />
    };

    const CloseIcon = props => {
        if(props.active) {
            return <Close color="secondary" />
        }
        return  <Close />
    };

    return (
        <ThemeProvider theme={theme}>
            <Stepper alternativeLabel activeStep={props.activeStep} connector={null} id='questionContainer' >
                {props.results.map(item => {
                    return (
                        <Step id={`question${item.questionNumber}`} key={item.questionNumber} onClick={() => props.handleManualClick(item.questionNumber, item.correct)}>
                            <StepLabel StepIconComponent={item.correct ? CheckIcon : CloseIcon}>
                                {item.questionNumber}
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </ThemeProvider>
    )
};

ExamAnswerScroller.propTypes = {
    selectedItem: PropTypes.number,
    updateCallback: PropTypes.func,
    results: PropTypes.array
};

export default withRouter(ExamAnswerScroller);