import { fetchCurrentUser } from './AuthActions';

export const APP_INITIALISING = 'APP_INITIALISING';
export const APP_READY = 'APP_READY';
export const APP_ERROR = 'APP_ERROR';

/**
 * App initialisation started
 *
 * @return {Object} - Action
 */
function appInitialising() {
    return { type: APP_INITIALISING };
}

/**
 * Mark application as ready
 *
 * @return {Object} - Action
 */
function appReady() {
    return { type: APP_READY };
}

/**
 * Set application in general error state
 *
 * @param {Error} error - Error that caused the error state
 *
 * @return {Object} - Action
 */
function appError(error) {
    return {
        type: APP_ERROR,
        error
    };
}

/**
 * Async action for initialising application
 *
 * @return {Object} - Action
 */
export function initApp() {
    return async dispatch => {
        dispatch(appInitialising());

        try {
            await dispatch(fetchCurrentUser());

            dispatch(appReady());
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
            dispatch(appError(err));
        }
    };
}
