import React, {Component} from "react";
import {MobileStepper, createMuiTheme} from "@material-ui/core";
import {ArrowRight, ArrowLeft} from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/styles";

const theme = createMuiTheme({
    overrides: {
        MuiMobileStepper: {
            root: {
                backgroundColor: 'rgba(1,1,1,0)',
            },
            dotActive: {
                backgroundColor: '#009FDA',
                width: '12px',
                height: '12px',
            },
            dot: {
                backgroundColor: '#000',
                width: '12px',
                height: '12px',
            }
        }
    }
});

class SelectCarousel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeStep: 0
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.currentSkillName !== this.props.currentSkillName) {
            this.setState({
                activeStep: 0
            })
        }

        if(prevProps.externalSubskillController !== this.props.externalSubskillController) {
            this.setState({
                activeStep: this.props.externalSubskillController
            })
        }
    }

    handleNext = () => {
        if(this.state.activeStep < this.props.subSkillCount - 1) {
            this.setState({
                activeStep: this.state.activeStep + 1
            });
            this.props.updateCallback(this.state.activeStep + 1);
        }
    };

    handleBack = () => {
        if(this.state.activeStep > 0) {
            this.setState({
                activeStep: this.state.activeStep - 1
            });
            this.props.updateCallback(this.state.activeStep - 1);
        }
    };

    render() {
        return (
            <ThemeProvider theme={theme}>
                <MobileStepper
                    variant={'dots'}
                    activeStep={this.state.activeStep}
                    steps={this.props.subSkillCount}
                    position="static"
                    backButton={<ArrowLeft  style={{fontSize: '24px', padding: '16px'}} onClick={this.handleBack}/>}
                    nextButton={<ArrowRight style={{fontSize: '24px', padding: '16px'}} onClick={this.handleNext}/>}/>
            </ThemeProvider>
        );
    }

}

export default SelectCarousel;