import React, {Component} from "react";
import {createMuiTheme, FormControl, MenuItem, Select} from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

const theme = createMuiTheme( {
    overrides: {
        MuiFormControl: {
            root: {
                margin: '16px 0'
            }
        },
        MuiSelect: {
            root: {
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#333',
            },
            icon: {
                fontSize: '24px',
                top: 'calc(50% - 14px)'
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: 'none'
                },
                '&:after': {
                    borderBottom: 'none'
                },
                '&:hover:not(.Mui-disabled):before': {
                    borderBottom: 'none'
                }
            }
        },
        MuiMenuItem: {
            root:{
                fontSize: '12px',
                fontWeight: 'bold'
            }
        }
    }
});

class SelectOption extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCat: this.props.options[0].name
        };

        this.props.updateCallback(this.props.options[0].name);
    }

    handleSelect = event => {
        this.props.updateCallback(event.target.value);
        this.setState({
            selectedCat: event.target.value
        })
    };


    render() {
        return (
            <ThemeProvider theme={theme}>
                <FormControl>
                    {   this.props.options ? (
                        <Select
                            id="categoryName"
                            value={this.state.selectedCat}
                            onChange={this.handleSelect}
                        >
                            {this.props.options.map(item => {
                                return <MenuItem key={item.name} value={item.name}>{item.name.toUpperCase()}</MenuItem>
                            })}
                        </Select> ) : null
                    }
                </FormControl>
            </ThemeProvider>
        )
    }
}

export default SelectOption;