import React from 'react';
import {Container} from "@material-ui/core";

const IeErrorPage = () => {
    return (
        <>
            <header style={{width: '100%', height: '66px', background: '#8625FF'}}/>
            <Container maxWidth={'sm'}>
                <h2 style={{margin: '24px 0'}}>Sorry, this test doesn't work in your browser. Please use Chrome, Firefox or Safari. Thanks!</h2>
                <img alt={'sad pug looking at cake'} src={'/img/errorPug.jpg'} style={{width: '100%'}}/>
            </Container>
        </>
    )
};

export default IeErrorPage;