// Core
import React from 'react';
// Styles
import './Card.scss';
// Components
import Intro from '../info/Intro';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';

/**
 * A container for info/chart etc. components.
 *
 * Content is contained within a *li* element, so *Cards* should be
 * children of components that wrap their content in a *ul* or *ol* element, e.g. a *Layout* component.
 */
const Card = ({
    child,
    children,
    info,
    heading,
    styleOverrides,
    variant
}) => (
    <li
        className={variant ? variant : 'card'}
        style={styleOverrides}
    >
        <Intro
            heading={heading}
            info={info}
        />
        <section className={child}>
            {children}
        </section>
    </li>
);

Card.propTypes = {
    /** className for the child 'section' element */
    child: PropTypes.string,
    /** child elements */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    /** heading (HTML) */
    heading: PropTypes.string,
    /** info/detail content (HTML) */
    info: PropTypes.string,
    /** style overrides */
    styleOverrides: stylePropType,
    /** className for the component */
    variant: PropTypes.string
};

export default Card;
