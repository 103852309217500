// Core
import React from 'react';
import PropTypes from 'prop-types';
import NavItem from '../navigation/NavItem';
// Styles
import './Banner.scss';
// Components

/**
 * Renders a header and, if passed a *to* prop, a *NavItem*.
 */

const Banner = ({ action, icon, title, to }) => (
    <header role="banner">
        {to && <NavItem action={action} icon={icon} to={to} />}
        <h1>{title}</h1>
    </header>
);

Banner.propTypes = {
    /** *action* prop for NavItem */
    action: PropTypes.string,
    /** material-ui icon name for NavItem icon */
    icon: PropTypes.string,
    /** header title */
    title: PropTypes.string.isRequired,
    /** *to* prop for NavItem */
    to: PropTypes.string
};

export default Banner;
