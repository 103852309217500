import React from 'react';
import PropTypes from 'prop-types';
import ScoreChart from '../chart/ScoreChart';
import Card from '../layout/Card';
import ScoreChartLegendBar from './ScoreChartLegendBar';

import './ScoreChartLegend.scss';
import {colorPropType} from '../../propTypes';

/**
 * Displays a descriptive legend for score charts.
 */
const ScoreChartLegend = (props) => {
    const barComponent = (barProps) => (
        <ScoreChartLegendBar
            {...barProps}
            color={props.color}
        />
    );

    const cardClasses = props.hidden ? 'score-chart-legend hidden' : 'score-chart-legend';

    return (
        <ol
            className={cardClasses}
            onClick={props.onClick}
        >
            <Card heading={props.heading}>
                <ScoreChart
                    color={props.color}
                    cutoff={{
                        name: 'Target',
                        value: 50
                    }}
                    percentageResult={100}
                    barComponent={barComponent}
                />
            </Card>
        </ol>
    );
};

ScoreChartLegend.defaultProps = {
    heading: 'LEGEND'
};

ScoreChartLegend.propTypes = {
    /** colors to configure example chart */
    color: colorPropType,
    /** legend heading */
    heading: PropTypes.string,
    /** whether the component is hidden; if true a *hidden* class will be added to the component */
    hidden: PropTypes.bool,
    /** onClick handler */
    onClick: PropTypes.func
};

export default ScoreChartLegend;
