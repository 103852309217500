// Core
import React from 'react';
// Styles
import './Intro.scss';
import PropTypes from 'prop-types';

/**
 * Renders introductory information, e.g. a heading and, optionally,
 * detailed info.
 *
 * Pass raw HTML to display in the *heading* and *info* props.
 */
const Intro = ({ heading, info }) =>  (
    <React.Fragment>
        <header dangerouslySetInnerHTML={{ __html: heading }} />
        {info && <section dangerouslySetInnerHTML={{ __html: info }} />}
    </React.Fragment>
);

Intro.propTypes = {
    /** heading HTML */
    heading: PropTypes.string,
    /** optional info HTML */
    info: PropTypes.string
};

export default Intro;
