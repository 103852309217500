import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Review from './Review';
import {TITLE_PREFIX} from '../Constants';
import GetResultsWithRetries from '../lib/resultsWithRetry';

class ReviewContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    componentDidMount() {
        const {
            question,
            session,
            skill
        } = this.props.match.params;

        document.title = `${TITLE_PREFIX}${skill} | ${question}`;

        GetResultsWithRetries(session, results => {
            const questions = results.responses.filter(r => r.skill === skill);
            this.setState({questions});
        }, results => {}, error => {
            this.setState({error});
        });
    }

    render() {
        if (this.state.error) {
            throw this.state.error;
        }

        const {
            question,
            skill
        } = this.props.match.params;

        return (
            <Review
                question={question}
                skill={skill}
                responses={this.state.questions}
                resultsHomeUrl={this.props.resultsHomeUrl}
            />);
    }
}

ReviewContainer.propTypes = {
    resultsHomeUrl: PropTypes.string.isRequired
};

export default ReviewContainer;
