import React from 'react';
import PropTypes from 'prop-types';
import Utils from '../../lib/Utils';
import FuzzyGradient from '../color/FuzzyGradient';

/**
 * A fuzzy ScoreChartBar - ends in the fade to transparent, to indicate a level of
 * uncertainty regarding the trainee's score/result.
 */
const ScoreChartBarFuzzy = ({
    color,
    fuzziness,
    height,
    maxWidth,
    width: _width,
    x,
    y
}) => {
    const gradientId = `score-chart-bar-gradient-${Utils.getUniqueId()}`;
    const width = _width + fuzziness > maxWidth ? maxWidth : _width + fuzziness;

    return (
        <React.Fragment>
            <defs>
                <FuzzyGradient
                    color={color}
                    fuzziness={fuzziness}
                    id={gradientId}
                    size={width}
                />
            </defs>
            <g transform={`translate(${x},${y})`}>
                <rect
                    width={width}
                    height={height}
                    rx="0"
                    ry="0"
                    fill={`url(#${gradientId})`}
                />
            </g>
        </React.Fragment>
    );
};

ScoreChartBarFuzzy.defaultProps = {
    fuzziness: 15
};

ScoreChartBarFuzzy.propTypes = {
    /** the bar color */
    color: PropTypes.string,
    /** size of the ending fade to transparent, in pixels */
    fuzziness: PropTypes.number,
    /** x position */
    x: PropTypes.number,
    /** y position */
    y: PropTypes.number,
    /** width */
    width: PropTypes.number,
    /** height */
    height: PropTypes.number,
    /** maximum possible width the bar could occupy */
    maxWidth: PropTypes.number.isRequired
};

export default ScoreChartBarFuzzy;
