import axios from 'axios';
import queryString from 'qs';

const BASE_URL = process.env.REACT_APP_API_URL;

/**
 * Makes API request using the specified HTTP method
 *
 * @param {String} method - HTTP method of the created request function
 *
 * @return {Function} - request function
 */
const requestFunction = method =>
    async (requestUrl, options = {}) => {
        const qs = queryString.stringify(options.qs || []);
        const json = JSON.stringify(options.body);

        const headers = {
            ...API.authToken && { Authorization: `Bearer ${API.authToken}` },
            ...json && { 'Content-Type': 'application/json' }
        };

        const instance = axios.create({
            baseURL: BASE_URL,
            headers
        });

        const url = qs ? `${requestUrl}?${qs}` : requestUrl;

        let result;

        try {
            result = await instance.request({
                url,
                method,
                data: json
            });
        } catch(error) {
            if(options.nullOnMissing) {
                return null;
            }
            if(error.response.status === 401 || error.response.status === 403) {
                console.log("You don't have permissions. Add a jwt 'authToken' to localstorage")
            }
            throw error;
        }

        return result && result.data;
    };

const API = {
    authToken: localStorage.getItem('authToken'),
    get: requestFunction('get'),
    post: requestFunction('post'),
    put: requestFunction('put'),
    del: requestFunction('delete')
};

export default API;
