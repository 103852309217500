import API from '../lib/api';

export const REQUEST_CURRENT_USER = 'REQUEST_CURRENT_USER';
export const ERROR_CURRENT_USER = 'ERROR_CURRENT_USER';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const LOGOUT = 'LOGOUT';

/**
 * Request for fetching current user started
 *
 * @return {Object} - Action
 */
function requestCurrentUser() {
    return { type: REQUEST_CURRENT_USER };
}

/**
 * Request for fetching current user failed
 *
 * @param {Error} error - Error that caused the request to fail
 *
 * @return {Object} - Action
 */
function errorCurrentUser(error) {
    return {
        type: ERROR_CURRENT_USER,
        error
    };
}

/**
 * Current user received successfully
 *
 * @param {Object} user - received user
 *
 * @return {Object} - Action
 */
export function receiveCurrentUser(user) {
    return {
        type: RECEIVE_CURRENT_USER,
        user
    };
}

/**
 * Async action for fetching current user
 *
 * @return {Function} - Action
 */
export function fetchCurrentUser() {
    return async dispatch => {
        dispatch(requestCurrentUser());

        try {
            const json = await API.get('/users/me');

            dispatch(receiveCurrentUser(json));
        } catch (err) {
            dispatch(errorCurrentUser(err));
        }
    };
}

/**
 * Login request started
 *
 * @return {Object} - Action
 */
function loginRequest() {
    return { type: LOG_IN_REQUEST };
}

/**
 * Login success
 *
 * @param {*} token - received login token
 * @param {*} user - received user
 *
 * @return {Object} - Action
 */
function loginSuccess(token, user) {
    return {
        type: LOG_IN_SUCCESS,
        token,
        user
    };
}

/**
 * Login error
 *
 * @param {Object} error - received error
 *
 * @return {Object} - Action
 */
function loginError(error) {
    return {
        type: LOG_IN_ERROR,
        error
    };
}

/**
 * Async action for logging in user
 *
 * @param {Object} credentials - credentials to use for login
 *
 * @return {Object} - Action
 */
export function loginUser(credentials) {
    return async dispatch => {
        const payload = {
            body: credentials
        };

        dispatch(loginRequest());

        try {
            const json = await API.post('/login', payload);

            dispatch(loginSuccess(json.token, json.user));
        } catch (err) {
            dispatch(loginError(err));
        }
    };
}

/**
 * Perform logout.
 *
 * @return {Object} Action.
 */
export function logoutUser() {
    return {
        type: LOGOUT
    };
}
