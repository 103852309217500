import React from 'react';
import PropTypes from 'prop-types';
import Dot from './Dot';
import SelectionArrows from './SelectionArrows';

import './SelectionDots.scss';

/**
 * Wraps an array of *Dot* components in *SelectionArrows*. *SelectionDots* can be used to
 * select pages/items etc.
 *
 * Invokes the function passed in the *onSelectionChange* prop whenever the selection changes,
 * passing the index of the selected item. It's up to the containing component to respond to
 * this change by updating the *selectedIndex* prop.
 */
const SelectionDots = (props) => {
    const handleChange = (e) => {
        const { onSelectionChange } = props;

        onSelectionChange(parseInt(e.target.value, 10));
    };

    const handleClickPrevious = () => {
        const { onSelectionChange, selectedIndex } = props;

        if (selectedIndex > 0) {
            onSelectionChange(selectedIndex - 1);
        }
    };

    const handleClickNext = () => {
        const { onSelectionChange, selectedIndex, titles } = props;

        if (selectedIndex < titles.length - 1) {
            onSelectionChange(selectedIndex + 1);
        }
    };

    const {
        name,
        nextTitle,
        previousTitle,
        selectedIndex,
        titles
    } = props;

    return (
        <fieldset className="selection-dots">
            <legend>Featured Content</legend>
            <SelectionArrows
                previousTitle={previousTitle}
                nextTitle={nextTitle}
                onClickPrevious={handleClickPrevious}
                onClickNext={handleClickNext}
            >
                {titles.map((title, i) =>
                    <Dot
                        key={i}
                        index={i}
                        checked={i === selectedIndex}
                        onChange={handleChange}
                        title={title}
                        name={name}
                    />
                )}
            </SelectionArrows>
        </fieldset>
    );
};

SelectionDots.defaultProps = {
    nextTitle: 'Next',
    previousTitle: 'Previous'
};

SelectionDots.propTypes = {
    /** name attribute for the Dots */
    name: PropTypes.string.isRequired,
    /** title attribute for *previous* arrow */
    previousTitle: PropTypes.string,
    /** title attribute for *next* arrow */
    nextTitle: PropTypes.string,
    /** onSelectionChange handler */
    onSelectionChange: PropTypes.func,
    /** which Dot is selected */
    selectedIndex: PropTypes.number.isRequired,
    /** an array of title attributes for the Dots */
    titles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default SelectionDots;
