import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AppRouter from './templates/AppRouter';

import './App.scss';
import LogRocket from 'logrocket-react';
import setupLogRocketReact from 'logrocket-react';

import {
    LoadingSpinner
} from './diagnostic-components';

const LOADING = 'LOADING';
const READY = 'READY';
const ERROR = 'ERROR';

const LOG_ROCKET_PROJECT = process.env.REACT_APP_LOGROCKET_PROJECT;

/**
 * Main app component
 */
const App = ({initState}) => {
    if (initState === READY) {
        return (
            <AppRouter />
        );
    } else if (initState === ERROR) {
        return (
            <pre>Error.</pre>
        );
    }

    return (
        <LoadingSpinner />
    );
};

App.propTypes = {
    initState: PropTypes.string.isRequired,
    error: PropTypes.any
};

/**
 * Returns a string describing the app init state
 *
 * @param {Object} app - app state
 *
 * @return {String} - init state
 */
function getInitState(app) {
    if (!app.ready) {
        return LOADING;
    } else if (app.error && app.error.status !== 401) { // don't handle 401 as an error so the user gets redirected to the login page
        return ERROR;
    }

    if(window.LogRocket) {
        window.LogRocket.init(LOG_ROCKET_PROJECT, {rootHostname: 'dnpd.cloud'});
        setupLogRocketReact(LogRocket);
    }

    return 'READY';
}

const mapStateToProps = state => ({
    initState: getInitState(state.app)
});

export default connect(
    mapStateToProps
)(App);
