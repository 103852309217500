import PropTypes from 'prop-types';

const navItemsPropType = PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    action: PropTypes.string
}));

export {
    navItemsPropType
};
