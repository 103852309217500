import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

/**
 * Route that redirects to the login page if no user is authenticated
 */
class PrivateRoute extends Component {
    render() {
        const { component: Component, isAuthenticated, ...rest } = this.props;

        return (
            <Route {...rest} render={props => (
                isAuthenticated ?
                    (
                        <Component {...props} {...rest} />
                    ) : (
                        <Redirect to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }} />
                    )
            )} />
        );
    }
}

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    isAuthenticated: !!state.auth.user
});

export default connect(
    mapStateToProps
)(PrivateRoute);
