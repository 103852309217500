// Core
import React from 'react';
// Styles
import './Icon.scss';
import PropTypes from 'prop-types';

// DEPENDANCY : MATERIAL ICONS NEED INCLUDING IN <HEAD> OF PUBLIC/INDEX.HTML
/**
 * Wraps a material-ui icon.
 */
const Icon = ({ icon }) => <i className="material-icons">{icon}</i>;

Icon.propTypes = {
    /** the material-ui icon name */
    icon: PropTypes.string.isRequired
};

export default Icon;
